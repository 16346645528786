import { IconCheck, IconMinus } from '@tabler/icons-react'
import clsx from 'clsx'
import type { CheckboxInputProps } from './types'

export const CheckboxInput = (props: CheckboxInputProps) => {
  const { indeterminate, className, ...rest } = props

  const iconClassName =
    'absolute left-2/4 top-2/4 block -translate-x-2/4 -translate-y-2/4 text-white '
  const iconSize = 18
  const iconStroke = 3

  return (
    <span className="relative size-5">
      <input
        type="checkbox"
        className={clsx(
          "before:content[''] peer size-5 appearance-none rounded border-[3px] transition-all",
          indeterminate && 'border-azure bg-azure',
          !indeterminate &&
            'border-night-200 checked:border-azure checked:bg-azure disabled:checked:border-night-300 disabled:checked:bg-night-300',
          className,
        )}
        {...rest}
      />
      {indeterminate ? (
        <IconMinus className={iconClassName} size={iconSize} stroke={iconStroke} />
      ) : (
        <IconCheck
          className={clsx(iconClassName, 'opacity-0 transition-opacity peer-checked:opacity-100')}
          size={iconSize}
          stroke={iconStroke}
        />
      )}
    </span>
  )
}
