import type { MenuProps } from '@mui/base/Menu'
import { Menu as BaseMenu } from '@mui/base/Menu'
import { resolveComponentProps } from '@mui/base/utils'
import clsx from 'clsx/lite'

export const Menu = (props: MenuProps) => {
  return (
    <BaseMenu
      {...props}
      slotProps={{
        ...props.slotProps,
        root: (ownerState) => {
          const resolvedSlotProps = resolveComponentProps(props.slotProps?.root, ownerState)
          return {
            ...resolvedSlotProps,
            className: clsx(resolvedSlotProps?.className, 'z-[2]'),
          }
        },
        listbox: (ownerState) => {
          const resolvedSlotProps = resolveComponentProps(props.slotProps?.listbox, ownerState)
          return {
            ...resolvedSlotProps,
            className: clsx(
              'mx-0 my-3 overflow-auto rounded-xl bg-white font-sans text-sm text-night-800 shadow-lg',
              resolvedSlotProps?.className,
            ),
          }
        },
      }}
    />
  )
}
