import { IconFileDownload } from '@tabler/icons-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, type StandardButtonProps } from '../Button'
import { DataTableExportDialog } from '../DataTableExportDialog'

interface DataTableExportButtonProps extends StandardButtonProps {}

export const DataTableExportButton = (props: DataTableExportButtonProps) => {
  const { t } = useTranslation()

  const [exportDialogOpen, setExportDialogOpen] = useState(false)

  return (
    <>
      <Button
        variant="secondary"
        startIcon={IconFileDownload}
        onClick={() => {
          setExportDialogOpen(true)
        }}
        data-table-id="data-table-export-button"
        {...props}
      >
        {t('action.export')}
      </Button>

      {exportDialogOpen && (
        <DataTableExportDialog
          onCancel={() => setExportDialogOpen(false)}
          modalProps={{ open: true }}
        />
      )}
    </>
  )
}
