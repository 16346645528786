import { IconTrash } from '@tabler/icons-react'
import clsx from 'clsx'
import { type ArrayHelpers, useFormikContext } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce, usePrevious } from 'react-use'
import { SelectOption } from '~/components/UI/Select/SelectOption'
import { useFilterFieldOptionsQuery } from '~/hooks/queries/useFilterFieldOptionsQuery'
import { useColumnsByDatamartQuery } from '~/pages/Administration/AdministrationDataPermissionPage/hooks'
import type { FilterEmpty } from '~/providers/FiltersProvider'
import {
  activeStringOperators,
  mergedFilterOperators,
  numericFiltersOperators,
} from '~/providers/FiltersProvider/helpers'
import type { OptionalClassName } from '~/types'
import type { FilterOption, FilterOptionDatatype } from '~/types/apiContracts'
import { AutocompleteControlled } from '../UI/AutocompleteControlled'
import { IconButton } from '../UI/IconButton'
import { InputControlled } from '../UI/InputControlled'
import { SelectControlled } from '../UI/SelectControlled'
import { getSelectedFieldOptions } from './helpers'

type FilterItemPropsBase = OptionalClassName & {
  moduleId: string
  datamart?: string
  filter: FilterEmpty
  index: number
  totalFilters: number
  arrayHelpers: ArrayHelpers
  clearAllFilters: () => void
  formField?: string
}

type FilterItemPropsWithAutocomplete = FilterItemPropsBase & {
  autocompleteField: true
  fieldOptions?: never
}

type FilterItemPropsWithoutAutocomplete = FilterItemPropsBase & {
  autocompleteField?: false
  fieldOptions: FilterOption[]
}

type FilterItemProps = FilterItemPropsWithAutocomplete | FilterItemPropsWithoutAutocomplete

export const FilterItem = (props: FilterItemProps) => {
  const { setFieldValue } = useFormikContext()
  const { t } = useTranslation(['common', 'filter'])
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('')
  const [debouncedAutocompleteInputValue, setDebouncedAutocompleteInputValue] = useState('')
  const {
    filter,
    index,
    arrayHelpers,
    autocompleteField = false,
    datamart = null,
    fieldOptions = [],
    formField = 'filters',
  } = props
  const [debounceAutocompleteColumnDatamart, setDebounceAutocompleteColumnDatamart] = useState('')
  const [autocompleteColumnDatamart, setAutocompleteColumnDatamart] = useState('')
  const prevFilterFieldValue = usePrevious(filter.field)

  useEffect(() => {
    // if filter field changes
    if (prevFilterFieldValue && prevFilterFieldValue !== filter.field) {
      // reset filter value
      setFieldValue(`${formField}.${index}.column_id`, null)
      setFieldValue(`${formField}.${index}.value`, undefined)
      setFieldValue(`${formField}.${index}.operator`, 'in')
      setAutocompleteInputValue('')
    }
  }, [filter.field, prevFilterFieldValue, setFieldValue, index, formField])

  useEffect(() => {
    const column = filter.column
    if (autocompleteField) {
      setFieldValue(`${formField}.${index}.field`, column?.field || '')
      setFieldValue(`${formField}.${index}.column_id`, column?.id || null)
    }
  }, [filter.column, autocompleteField, index, setFieldValue, formField])

  const selectedField = useMemo(() => {
    if (autocompleteField) {
      return filter.column as unknown as FilterOption
    }
    return fieldOptions.find((option) => filter.field && option.field === filter.field)
  }, [filter.field, filter.column, fieldOptions, autocompleteField])

  const datatypeFieldSelected = selectedField?.data_type as FilterOptionDatatype

  const isNumericField = datatypeFieldSelected === 'NUMERIC'

  const operatorOptions = mergedFilterOperators
    .filter((operator) => {
      if (isNumericField) {
        return [...numericFiltersOperators, ...activeStringOperators].includes(operator)
      }

      return activeStringOperators.includes(operator)
    })
    .map((operator) => ({
      label: t(`operator.${operator}`),
      value: operator,
    }))

  const getColumnsByDatamart = useColumnsByDatamartQuery({
    datamart,
    search: debounceAutocompleteColumnDatamart,
  })

  const datamartColumns = useMemo(() => {
    return getColumnsByDatamart.data?.items || []
  }, [getColumnsByDatamart.data])

  const _fieldOptions = useMemo(() => {
    return datamartColumns.map((column) => {
      return {
        ...column,
        value: column.field,
        datatype: column.data_type,
        field_name: column.label,
      }
    })
  }, [datamartColumns])

  const filterFieldOptionsQuery = useFilterFieldOptionsQuery({
    moduleId: props.moduleId,
    fieldName: selectedField?.field as string,
    search: debouncedAutocompleteInputValue,
    enabled: !!selectedField?.field && !['NUMERIC', 'WEEK_DAYS'].includes(datatypeFieldSelected),
  })

  const selectedFieldOptions = getSelectedFieldOptions(
    datatypeFieldSelected,
    filterFieldOptionsQuery?.data?.options || [],
  )

  useDebounce(
    () => {
      setDebouncedAutocompleteInputValue(autocompleteInputValue)
    },
    500,
    [autocompleteInputValue],
  )

  useDebounce(
    () => {
      setDebounceAutocompleteColumnDatamart(autocompleteColumnDatamart)
    },
    500,
    [autocompleteColumnDatamart],
  )

  // TODO: keep this line and add the necessary operators here when they are ready in the back
  const shouldShowValueField =
    filter.operator && ['in', 'not in', 'greater than', 'less than'].includes(filter.operator)

  return (
    <div
      className={clsx(
        'flex flex-col gap-4 px-5 py-8 @2xl:flex-row @2xl:items-center md:pe-6 md:ps-10',
        props.className,
      )}
    >
      <div className="min-w-[28%]">
        {autocompleteField ? (
          <AutocompleteControlled
            id={`${formField}.${index}.column`}
            name={`${formField}.${index}.column`}
            options={_fieldOptions}
            placeholder={t('field')}
            inputValue={autocompleteColumnDatamart}
            onInputChange={(_, inputValue) => {
              setAutocompleteColumnDatamart(inputValue)
              setAutocompleteInputValue('')
            }}
            clearOnBlur={false}
            popupClassName="z-[510]"
            isOptionEqualToValue={() => true}
            isLoading={getColumnsByDatamart?.isFetching}
            size="large"
          />
        ) : (
          <SelectControlled
            name={`${formField}.${index}.field`}
            placeholder={t('field')}
            className="w-full"
            slotProps={{
              popup: {
                className: 'z-[510]',
              },
            }}
            onListboxOpenChange={() => {
              setAutocompleteInputValue('')
            }}
            size="large"
          >
            {fieldOptions.map((option, i) => {
              return (
                <SelectOption key={`${option.field}-${i}`} value={option.field}>
                  {option.label}
                </SelectOption>
              )
            })}
          </SelectControlled>
        )}
      </div>
      <div className="min-w-[24%]">
        <SelectControlled
          name={`${formField}.${index}.operator`}
          placeholder={t('condition')}
          disabled={!filter.field}
          className="w-full"
          size="large"
        >
          {operatorOptions.map((option) => (
            <SelectOption key={option.value} value={option.value}>
              {option.label}
            </SelectOption>
          ))}
        </SelectControlled>
      </div>
      <div className="flex-grow">
        {shouldShowValueField &&
          (isNumericField ? (
            // TODO: validate why, even though the number input has the same height as the selects with the same class, they do not have the same height on the screen
            <InputControlled
              format="number"
              name={`${formField}.${index}.value`}
              autoComplete="off"
              size="large"
              placeholder={t('value')}
            />
          ) : (
            <AutocompleteControlled
              id={`${formField}.${index}.value`}
              name={`${formField}.${index}.value`}
              options={selectedFieldOptions}
              placeholder={t('options')}
              multiple
              inputValue={autocompleteInputValue}
              onInputChange={(_, inputValue) => {
                setAutocompleteInputValue(inputValue)
              }}
              clearOnBlur={false}
              popupClassName="z-[510]"
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) => option.value === value.value}
              disabled={!filter.field || !filter.operator}
              isLoading={filterFieldOptionsQuery?.isFetching}
              size="large"
            />
          ))}
      </div>
      <IconButton
        onClick={() => {
          if (props.totalFilters === 1) {
            props.clearAllFilters()
          } else {
            arrayHelpers.remove(index)
          }
        }}
        title={t('action.remove')}
        className="self-center"
      >
        <IconTrash size={20} />
      </IconButton>
    </div>
  )
}
