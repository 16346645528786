import clsx from 'clsx/lite'
import type { ComponentProps } from 'react'

export interface DataTableToolbarProps extends ComponentProps<'div'> {}

export const DataTableToolbar = (props: DataTableToolbarProps) => {
  return (
    <div
      data-table-id="data-table-toolbar"
      className={clsx('mb-4 flex items-center', props.className)}
    >
      {props.children}
    </div>
  )
}
