import { Navigate, createFileRoute } from '@tanstack/react-router'
import { useAuthContext } from '~/providers/AuthProvider'

const IndexComponent = () => {
  const { isAuthenticated, tenantDefaultLandingUrl } = useAuthContext()

  const navigateUrl = tenantDefaultLandingUrl || '/dashboard'

  return <Navigate to={isAuthenticated ? navigateUrl : '/login'} />
}

export const Route = createFileRoute('/')({
  component: IndexComponent,
})
