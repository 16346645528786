import { createFileRoute } from '@tanstack/react-router'
import { redirectIfPathnamesMatch } from '~/utils/routing'

export const Route = createFileRoute('/_layout/reports-and-analytics')({
  beforeLoad: ({ location }) => {
    redirectIfPathnamesMatch({
      pathname: location.pathname,
      expectedPathname: '/reports-and-analytics',
      redirectTo: '/reports-and-analytics/overall-performance',
    })
  },
})
