import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import { listWithKeyDateSearch } from '~/lib/searchSchemas'

const replenishmentSuggestionsSearch = z
  .object({
    warehouse: z.string().optional(),
  })
  .and(listWithKeyDateSearch)

export const Route = createFileRoute('/_layout/work-area/replenishment-suggestions/')({
  validateSearch: replenishmentSuggestionsSearch,
})
