import {
  gridPageCountSelector,
  gridPaginationModelSelector,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import clsx from 'clsx'
import type { OptionalClassName } from '~/types'
import { Pagination } from '../Pagination'
import { useDataTableContext } from './DataTableContext'
import { DataTableRowCount } from './DataTableRowCount'

interface DataTablePaginationProps extends OptionalClassName {}

export const DataTablePagination = (props: DataTablePaginationProps) => {
  const { apiRef, rootProps } = useDataTableContext()

  const { pageSizeOptions } = rootProps

  const pageCount = useGridSelector(apiRef, gridPageCountSelector)
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector)

  const handleChangePage = (page: number) => {
    apiRef.current.setPage(page)
  }

  const handlePageSizeChange = (pageSize: number) => {
    apiRef.current.setPageSize(pageSize)
  }

  const pagination = rootProps.pagination

  const component = pagination ? (
    <Pagination
      data-table-id="data-table-pagination"
      page={paginationModel.page}
      pageSize={paginationModel.pageSize}
      pageCount={pageCount}
      pageSizeOptions={pageSizeOptions}
      onChangePage={handleChangePage}
      onChangePageSize={handlePageSizeChange}
    />
  ) : (
    <DataTableRowCount className="ml-auto" />
  )

  return (
    <div className={clsx('mt-auto', !pagination && 'justify-self-end', props.className)}>
      {component}
    </div>
  )
}
