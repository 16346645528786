import { useAutocomplete } from '@mui/base'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import { IconChevronDown } from '@tabler/icons-react'
import clsx from 'clsx'
import { isArray, isEqual, omit } from 'lodash-es'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import type { FilterArrayValue } from '~/providers/FiltersProvider'
import { AutocompleteTag } from '../AutocompleteTag'
import { Checkbox } from '../Checkbox'
import { Loader } from '../Loader'
import type { AutocompleteProps } from './types'

// Use this componente width a parent with a defined width
export const Autocomplete = <
  TValue extends FilterArrayValue,
  TMultiple extends boolean = true,
  TDisableClearable extends boolean = true,
  TFreeSolo extends boolean = false,
>({
  className,
  isLoading,
  size = 'medium',
  placeholder,
  popupClassName,
  renderTags = 2,
  ...rest
}: AutocompleteProps<TValue, TMultiple, TDisableClearable, TFreeSolo>) => {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    anchorEl,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    popupOpen,
    setAnchorEl,
    dirty,
  } = useAutocomplete(rest)

  const inputProps = omit(getInputProps(), ['size'])

  const { t } = useTranslation()

  const options = groupedOptions as TValue[]

  const tagValues = isArray(value)
    ? ([...value].slice(0, focused ? value.length : renderTags) as FilterArrayValue[])
    : []

  const handleSelectAll = () => {
    const selectedOptions = isEqual(value, options) ? [] : options

    const event = {
      target: { value: selectedOptions },
    } as unknown as React.ChangeEvent<HTMLInputElement>

    // TODO: validate later
    rest.onChange?.(event, selectedOptions as never, 'selectOption')
  }

  const hasOptions = options.length > 0

  const containerRef = useRef<HTMLDivElement | null>(null)

  const shouldRenderHiddenTagsCounter =
    rest.multiple && value && Array.isArray(value) && value?.length > renderTags && !focused

  useEffect(() => {
    if (containerRef.current) {
      // @ts-expect-error setAnchorEl does not expect any arguments according to the typing, but if the reference is not provided, the suggestion menu does not work
      setAnchorEl(containerRef.current)
      // Scroll from the bottom of the container
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
  }, [setAnchorEl])

  return (
    <div {...getRootProps()}>
      <div
        ref={containerRef}
        className={clsx(
          'relative flex max-h-36 w-full flex-wrap items-center overflow-y-auto rounded-xl pr-10 transition-all duration-75',
          focused ? 'outline-[3px] outline-azure' : 'outline-1 outline-night-200',
          size === 'small' && 'min-h-7',
          size === 'medium' && 'min-h-9',
          size === 'large' && 'min-h-11',
          rest.disabled ? 'bg-night-50 text-night-300' : 'outline',
          className,
        )}
      >
        {rest.multiple &&
          tagValues.map((option, index) => (
            <AutocompleteTag
              {...getTagProps({ index })}
              label={option?.label}
              size={size}
              disableDelete={rest.disabled}
              key={`${option.label}-${index}`}
            />
          ))}

        {shouldRenderHiddenTagsCounter && (
          <span className="ml-1"> +{value.length - renderTags} </span>
        )}

        <input
          className={clsx(
            'm-0 box-border h-7 w-0 min-w-7 flex-grow border-0 p-1 caret-azure outline-none placeholder:text-lg',
            size === 'medium' && 'px-4',
            size === 'small' && 'px-3 text-sm',
            size === 'large' && 'px-4 text-xl',
            dirty && '!p-1',
            rest.disabled && 'bg-transparent',
          )}
          {...inputProps}
          placeholder={placeholder && dirty ? undefined : placeholder}
        />

        <div className="absolute right-2 top-1/2 -translate-y-1/2 transform">
          <IconChevronDown />
        </div>
      </div>

      {popupOpen && (
        <BasePopup
          open={!!anchorEl}
          anchor={anchorEl}
          className={clsx(popupClassName)}
          placement="bottom-start"
          style={{ minWidth: anchorEl ? anchorEl?.offsetWidth : 'auto' }}
        >
          <ul
            {...getListboxProps()}
            className="my-1 grid max-h-80 w-full gap-1 overflow-auto bg-white p-1.5 text-sm text-night-800 shadow shadow-night-200 outline-0"
          >
            {isLoading && (
              <li className="flex w-full items-center gap-2 p-2 md:col-span-2">
                <Loader iconSize={25} /> <span>{t('status.loading')}</span>
              </li>
            )}

            {hasOptions && !isLoading && rest.multiple && options.length > 1 && (
              <li
                onClick={handleSelectAll}
                className="flex w-full cursor-pointer list-none items-center gap-2 p-2 hover:bg-seasalt focus:ring-2 focus-visible:outline focus-visible:outline-1 focus-visible:outline-azure md:col-span-2"
              >
                <Checkbox checked={isEqual(value, options)} readOnly />
                <span> {t('selectAll')} </span>
              </li>
            )}

            {!hasOptions && !isLoading && <li className="w-full p-2">{t('noOptions')}</li>}

            {!isLoading &&
              options.map((option, index) => {
                const optionProps = getOptionProps({ option, index })
                const isSelected = !!optionProps['aria-selected']

                return (
                  <li
                    {...optionProps}
                    className="flex w-full cursor-pointer list-none items-center gap-2 p-2 hover:bg-seasalt focus:ring-2 focus-visible:outline focus-visible:outline-1 focus-visible:outline-azure"
                    // TODO: check key error in console
                    key={`${option.label}-${index}`}
                  >
                    {rest.multiple ? <Checkbox checked={isSelected} readOnly /> : null}
                    <span>{option.label}</span>
                  </li>
                )
              })}
          </ul>
        </BasePopup>
      )}
    </div>
  )
}
