import { useQuery } from '@tanstack/react-query'
import { api } from '~/lib/api'
import { FILTERS_SETS } from '~/lib/queryKeys'
import { FilterSetSource } from '~/types/apiContracts'
import type { ApiResponse, FilersSet } from '~/types/apiContracts'

const getSavedFiltersSet = async (moduleId: string, userId: number | null) => {
  const res = (await api
    .get(`configs/query-builder/filter-sets/users/${userId}/app_name/${moduleId}`)
    .json()) as ApiResponse<{
    items: FilersSet[]
  }>
  return res.data
}

export const useSavedFiltersSetsQuery = (moduleId: string, userId: number | null) => {
  return useQuery({
    queryKey: [FILTERS_SETS, moduleId, userId],
    queryFn: () => getSavedFiltersSet(moduleId, userId),
    enabled: !!userId && !!moduleId,
    select: (data) => ({
      ...data,
      items: [...data.items].sort((a, b) =>
        a.source === b.source ? 0 : a.source === FilterSetSource.USER ? -1 : 1,
      ),
    }),
  })
}
