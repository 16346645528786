import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import type { DateRange, RangePosition } from '@mui/x-date-pickers-pro'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import { isEqual } from 'lodash-es'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../UI/Button'
import { DateRangePickerFilterHeader } from './DateRangePickerFilterHeader'
import { useDateRangePickerFilterContext } from './DateRangePickerFilterProvider'
import { dateRangePickerFilterSlotProps } from './helpers'
import type { DateRangePickerFilterMainProps } from './types'

export const DateRangePickerFilterMain = (props: DateRangePickerFilterMainProps) => {
  const { isOpen, popupAnchor, popupId, allowCompare, onCompareClick, onSave, ref, ...rest } = props

  const { saveMainDateRange, hasSettedDates, defaultValues, resetDateFilters, values } =
    useDateRangePickerFilterContext()

  const [internalValue, setInternalValue] = useState<DateRange<Date>>(
    hasSettedDates ? values.main : defaultValues.main,
  )
  const [rangePosition, setRangePosition] = useState<RangePosition>('start')

  const { t } = useTranslation()

  const handleMonthSelect = (dates: DateRange<Date>, rangePosition: RangePosition) => {
    setRangePosition(rangePosition)
    setInternalValue(dates)
  }

  const handleSaveDates = () => {
    setRangePosition('start')
    saveMainDateRange(internalValue)
    onSave()
  }

  const handleResetSettedDates = () => {
    resetDateFilters()
    setInternalValue(defaultValues.main)
  }

  const hasChangedDates = !isEqual(internalValue, defaultValues.main)

  return (
    <BasePopup
      ref={ref}
      id={popupId}
      open={isOpen}
      anchor={popupAnchor}
      placement="bottom-end"
      className="z-50 mt-2 rounded-lg border border-neutral-200 bg-white p-3 shadow-md"
    >
      <div className="p-3">
        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          slotProps={dateRangePickerFilterSlotProps}
          rangePosition={rangePosition}
          onRangePositionChange={(position) => {
            setRangePosition(position)
          }}
          onChange={(newDate) => {
            setInternalValue(newDate)
          }}
          slots={{
            calendarHeader: (props) => (
              <DateRangePickerFilterHeader
                onMonthSelect={handleMonthSelect}
                value={internalValue}
                {...props}
              />
            ),
          }}
          value={internalValue}
          {...rest}
        />
        <div className="flex justify-between">
          {allowCompare && (
            <Button variant="ghost" onClick={onCompareClick}>
              {t('addDateComparison')}
            </Button>
          )}

          {hasSettedDates && (
            <Button variant="primary-text" className="ml-auto" onClick={handleResetSettedDates}>
              {t('action.reset')}
            </Button>
          )}

          <Button className="ml-auto" onClick={handleSaveDates} disabled={!hasChangedDates}>
            {t('action.save')}
          </Button>
        </div>
      </div>
    </BasePopup>
  )
}
