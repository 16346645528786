import { createRouter } from '@tanstack/react-router'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { ErrorScreenNotFound } from '~/components/ErrorScreen'
// Import the auto generated route tree
import { routeTree } from '../routeTree.gen'
import { customParser, customStringifier } from '../utils/routing'
import { queryClient } from './queryClient'

export const router = createRouter({
  routeTree,
  context: {
    // biome-ignore lint/style/noNonNullAssertion: this will be set after we wrap the app in the AuthProvider
    auth: null!,
    queryClient,
  },
  //https://github.com/TanStack/router/issues/1534 - defaultPreload is not working as expected with nested routes. Disabled for now
  defaultPreload: false,
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  stringifySearch: customStringifier,
  parseSearch: customParser,
  defaultNotFoundComponent: ErrorScreenNotFound,
})

export type Router = typeof router

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: Router
  }
}

type RoutesSearch = Record<string, Record<string, Record<string, unknown>>>

export const useRoutesSearch = create(
  persist<RoutesSearch>(
    () => ({
      routesSearch: {},
    }),
    {
      name: 'RoutesSearch',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
)

// save the search params for each route
router.subscribe('onResolved', (event) => {
  useRoutesSearch.setState((prev) => ({
    routesSearch: {
      ...prev.routesSearch,
      [event.toLocation.pathname]: event.toLocation.search,
    },
  }))
})
