import { Form, FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Feature, FeatureFlags } from '~/components/Feature'
import { useFiltersSetCreateMutation } from '~/hooks/mutations/useFiltersSetCreateMutation'
import { useAuthContext } from '~/providers/AuthProvider'
import type { FilterArrayValue } from '~/providers/FiltersProvider'
import { validateFormValues } from '~/utils/formik'
import { Button } from '../../UI/Button'
import { InputControlled } from '../../UI/InputControlled'
import { RadioButtonControlled } from '../../UI/RadioButtonControlled'
import { convertOperatorByMode } from '../helpers'
import { initialValues, validationSchema } from './helpers'
import type { FiltersSetFormProps, FiltersSetFormValues } from './types'

export const FiltersSetForm = (props: FiltersSetFormProps) => {
  const { t } = useTranslation()
  const { userId } = useAuthContext()

  const createFiltersSetMutation = useFiltersSetCreateMutation(props.moduleId)

  const formik = useFormik<FiltersSetFormValues>({
    initialValues,
    validate: (values) => validateFormValues(values, validationSchema),
    onSubmit: async (value, { resetForm }) => {
      await createFiltersSetMutation.mutateAsync(
        {
          name: value.name,
          datamart: 'supply', /// TODO: This should be handled on the backend, but the backend doesn’t support it yet, so it’s hardcoded.
          source: value.saveFor,
          user_id: userId,
          app_name: props.moduleId,
          /// TODO: This approach is necessary because some operator types need to be renamed during filter creation. However, the backend for get-items hasn’t been updated yet, so this adjustment is temporarily handled on the front-end.
          filters: (props.filters || []).map(({ field, operator, value }) => {
            return {
              name: '',
              column_id: props.fieldOptions.find((column) => column.field === field)?.id || 0,
              operator: convertOperatorByMode(operator, true),
              value: ((value as FilterArrayValue[]) || []).map(({ value }) => value),
            }
          }),
        },
        {
          onSuccess: () => {
            resetForm()
            props.onSubmitSuccess()
          },
        },
      )
    },
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className="divide-y divide-night-100">
          <div className="flex flex-wrap items-center gap-x-[76px] gap-y-6 px-10 py-6">
            <InputControlled
              name="name"
              placeholder={t('filterName')}
              className="me-[76px] min-w-[278px]"
              disabled={formik.isSubmitting}
              size="large"
            />
            <div className="flex gap-9">
              <Feature flag={FeatureFlags.ENABLE_SAVE_JUST_FOR_ME_IN_FILTER_CREATION}>
                {() => (
                  <RadioButtonControlled
                    name="saveFor"
                    value="user"
                    label={t('saveJustForMe')}
                    disabled={formik.isSubmitting}
                  />
                )}
              </Feature>
              <RadioButtonControlled
                name="saveFor"
                value="app"
                label={t('saveForEveryone')}
                disabled={formik.isSubmitting}
              />
            </div>
          </div>
          <div className="flex justify-end gap-6 px-8 pb-6 pt-4">
            <Button variant="primary-text" onClick={props.onCancel} size="large">
              {t('action.cancel')}
            </Button>
            <Feature
              flag={FeatureFlags.ENABLE_FILTER_CREATION}
              fallback={
                <Button disabled={true} isLoading={formik.isSubmitting} size="large">
                  {t('saveFilters')}
                </Button>
              }
            >
              {() => (
                <Button
                  type="submit"
                  disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                  isLoading={formik.isSubmitting}
                  size="large"
                >
                  {t('saveFilters')}
                </Button>
              )}
            </Feature>
          </div>
        </div>
      </Form>
    </FormikProvider>
  )
}
