import { ClickAwayListener } from '@mui/base'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import { IconChevronDown } from '@tabler/icons-react'
import clsx from 'clsx'
import { useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, type StandardButtonProps } from '../Button'
import { DataTableColumnsPanel } from './DataTableColumnsPanel/DataTableColumnsPanel'

interface DataTableColumnsPanelButtonProps extends StandardButtonProps {
  containerClassName?: string
}

export const DataTableColumnsPanelButton = (props: DataTableColumnsPanelButtonProps) => {
  const { t } = useTranslation()
  const [columnsPanelAnchor, setColumnsPanelAnchor] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(columnsPanelAnchor)
  const baseId = useId()
  const id = open ? baseId : undefined

  const handleClickAway = () => setColumnsPanelAnchor(null)

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        data-table-id="data-table-columns-panel-button"
        className={clsx('ms-auto', props.containerClassName)}
      >
        <Button
          variant="secondary"
          endIcon={IconChevronDown}
          onClick={(event) => {
            setColumnsPanelAnchor(
              columnsPanelAnchor ? null : (event.currentTarget as HTMLButtonElement),
            )
          }}
          {...props}
        >
          {t('columns')}
        </Button>

        <BasePopup
          id={id}
          open={open}
          anchor={columnsPanelAnchor}
          placement="bottom-end"
          disablePortal
          className="z-10 mt-2 bg-white shadow-popup"
        >
          <DataTableColumnsPanel />
        </BasePopup>
      </div>
    </ClickAwayListener>
  )
}
