import clsx from 'clsx'
import type { ContainerWithClassName } from '~/types/shared'

interface DataTableFooterProps extends ContainerWithClassName {
  disableMargin?: boolean
}

export const DataTableFooter = (props: DataTableFooterProps) => {
  return (
    <div
      data-table-id="data-table-footer"
      className={clsx(!props.disableMargin && 'mt-7', props.className)}
    >
      {props.children}
    </div>
  )
}
