/**
 * ⚠️ ⚠️ ⚠️
 *
 * THIS CODE WAS TAKEN FROM 'material-ui' REPOSITORY,
 * FROM THE '@mui/utils' PACKAGE.
 *
 * (in order to create our version of the 'GridColumnsManagement' component)
 *
 * SEE: https://github.com/mui/material-ui/blob/master/packages/mui-utils/src/useLazyRef/useLazyRef.ts
 *
 * ⚠️ ⚠️ ⚠️
 */
import { useRef } from 'react'

const UNINITIALIZED = {}

/**
 * A React.useRef() that is initialized lazily with a function. Note that it accepts an optional
 * initialization argument, so the initialization function doesn't need to be an inline closure.
 *
 * @usage
 *   const ref = useLazyRef(sortColumns, columns)
 */
export function useLazyRef<T, U>(init: (arg?: U) => T, initArg?: U) {
  const ref = useRef(UNINITIALIZED as unknown as T)

  if (ref.current === UNINITIALIZED) {
    ref.current = init(initArg)
  }

  return ref
}
