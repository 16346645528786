import { Button as BaseButton } from '@mui/base/Button'
import { Link } from '@tanstack/react-router'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import type { ViewDetailsActionsProps } from './types'

export const ViewDetailsAction = ({ touchRippleRef, ...props }: ViewDetailsActionsProps) => {
  const { t } = useTranslation(['common'])

  const baseClasses = clsx('text-caption-3 text-azure underline')
  const label = t('action.viewDetails')

  if (props.variant === 'link') {
    const { variant, className, children, ...rest } = props

    return (
      <Link className={clsx(baseClasses, className)} {...rest}>
        {props.children ?? label}
      </Link>
    )
  }

  const { variant, className, children, ...rest } = props

  return (
    <BaseButton className={clsx(baseClasses, className)} {...rest}>
      {props.children ?? label}
    </BaseButton>
  )
}
