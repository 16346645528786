import { Tab as BaseTab } from '@mui/base/Tab'
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel'
import { Tabs as BaseTabs } from '@mui/base/Tabs'
import { TabsList as BaseTabsList } from '@mui/base/TabsList'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import clsx from 'clsx/lite'
import { useCallback, useEffect, useRef, useState } from 'react'
import { TabsLink } from './TabsLink'
import type { TabsProps } from './types'

export const Tabs = (props: TabsProps) => {
  const tabsListRef = useRef<HTMLDivElement>(null)
  const [showScrollButtons, setShowScrollButtons] = useState(false)
  const [showLeftButton, setShowLeftButton] = useState(false)
  const [showRightButton, setShowRightButton] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)

  const updateScrollButtons = useCallback(() => {
    if (!tabsListRef.current) return
    const { scrollWidth, clientWidth, scrollLeft } = tabsListRef.current
    setShowScrollButtons(scrollWidth > clientWidth)
    const tolerance = 5
    setShowLeftButton(scrollLeft > 0)
    setShowRightButton(scrollLeft < scrollWidth - clientWidth - tolerance)
  }, [])

  useEffect(() => {
    updateScrollButtons()
    window.addEventListener('resize', updateScrollButtons)
    return () => {
      window.removeEventListener('resize', updateScrollButtons)
    }
  }, [updateScrollButtons])

  const scroll = (pixels: number) => {
    if (!tabsListRef.current) return
    setIsScrolling(true)
    tabsListRef.current.scrollBy({ left: pixels, behavior: 'smooth' })
    setTimeout(() => {
      setIsScrolling(false)
      updateScrollButtons()
    }, 300)
  }

  return (
    <div className="relative">
      {showScrollButtons && (
        <>
          <button
            type="button"
            className={clsx(
              'absolute -left-1 z-10 h-full w-8 transform bg-white pb-1 text-azure',
              !showLeftButton && 'hidden',
            )}
            onClick={() => scroll(-150)}
          >
            <IconChevronLeft />
          </button>
          <button
            type="button"
            className={clsx(
              'absolute -right-1 z-10 h-full w-8 transform bg-white pb-1 text-azure',
              !showRightButton && 'hidden',
            )}
            onClick={() => scroll(150)}
          >
            <IconChevronRight />
          </button>
        </>
      )}
      <BaseTabs
        ref={tabsListRef}
        className={clsx(props.className, 'no-scrollbar overflow-x-scroll')}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={(_event, value) => {
          props.onChange?.(value)
        }}
        onScroll={updateScrollButtons}
      >
        <BaseTabsList
          slotProps={{
            root: {
              className: 'flex gap-9.5 flex-shrink',
            },
          }}
          slot={props.asNav ? 'nav' : undefined}
        >
          {props.items.map((item) => {
            const { content, icon, ...rest } = item

            return (
              <BaseTab
                key={props.asNav ? item.to : item.value}
                {...rest}
                value={props.asNav ? item.to : item.value}
                slots={{
                  root: props.asNav ? TabsLink : rest.slots?.root,
                }}
                slotProps={{
                  root: (ownerState) => ({
                    className: clsx(
                      'text-body-2 inline-flex h-7.5 flex-shrink-0 items-center gap-2 border-b-2 pb-1.5 transition-colors duration-300',
                      ownerState.selected
                        ? 'text-body-2-strong border-azure'
                        : 'text-body-2 border-transparent',
                      isScrolling && 'pointer-events-none',
                    ),
                  }),
                }}
              >
                {icon}
                {item.children}
              </BaseTab>
            )
          })}
        </BaseTabsList>
        {props.showContent &&
          props.items.map((item) => {
            return (
              <BaseTabPanel key={item.value} value={item.value}>
                {item.content}
              </BaseTabPanel>
            )
          })}
      </BaseTabs>
    </div>
  )
}
