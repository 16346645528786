import MuiPagination from '@mui/material/Pagination'
import { isObject } from 'lodash-es'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { PaginationProps } from './types'

export const Pagination = (props: PaginationProps) => {
  const {
    page,
    pageSize,
    pageSizeOptions: pageSizeOptionsProp = [25, 50, 100],
    onChangePage,
    onChangePageSize,
    pageCount: pageCountProp,
    total,
    className,
    ...rest
  } = props
  const { t } = useTranslation()

  const pageCount = total !== undefined ? Math.ceil(total / pageSize) : (pageCountProp ?? 0)

  const pageSizeOptions = useMemo(() => {
    const options = Array.from(pageSizeOptionsProp)

    if (!options.includes(pageSize)) {
      options.push(pageSize)
      options.sort((a, b) => {
        if (isObject(a) && isObject(b)) {
          return a.value - b.value
        }

        if (typeof a === 'number' && typeof b === 'number') {
          return a - b
        }

        return 0
      })
    }

    return options
  }, [pageSizeOptionsProp, pageSize])

  return (
    <div className="flex flex-col items-center gap-4 md:flex-row md:justify-between" {...rest}>
      <label className="text-body-2 flex items-center gap-6">
        {t('rowsPerPage')}
        <select
          value={pageSize}
          onChange={(event) => {
            onChangePageSize(Number(event.target.value))
          }}
          className="text-body-2 cursor-pointer border-none bg-transparent"
        >
          {pageSizeOptions.map((pageSizeOption) => {
            const option = isObject(pageSizeOption) ? pageSizeOption.value : pageSizeOption

            return (
              <option key={option} value={option}>
                {option}
              </option>
            )
          })}
        </select>
      </label>

      <MuiPagination
        count={pageCount}
        page={page + 1}
        onChange={(_e, newPage) => {
          onChangePage(newPage - 1)
        }}
      />
    </div>
  )
}
