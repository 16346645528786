import clsx from 'clsx'
import 'react'
import reactHotToast from 'react-hot-toast'
import { Alert } from '../Alert'
import type { CustomToastOptions } from './types'

export function toast({
  id,
  status = 'success',
  duration = 2000,
  content,
  ...rest
}: CustomToastOptions) {
  return reactHotToast.custom(
    // biome-ignore lint/suspicious/noExplicitAny: we don't know the type yet
    (t: any) => {
      return (
        <div
          className={clsx(
            'flex max-w-lg rounded-lg bg-white shadow-lg ring-1 ring-neutral-700 ring-opacity-5',
            t.visible
              ? 'animate-in fade-in zoom-in'
              : 'animate-out fade-out zoom-out fill-mode-forwards',
          )}
        >
          <Alert className="w-full" status={status}>
            {typeof content === 'function' ? content(t) : content}
          </Alert>
        </div>
      )
    },
    { duration, id: id || crypto.randomUUID(), ...rest },
  )
}

toast.dismiss = reactHotToast.dismiss

export function toastError(errorMsg: string) {
  toast({ content: errorMsg, status: 'error' })
  return new Error(errorMsg)
}
