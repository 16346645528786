import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import type { GridFileExportOptions } from '@mui/x-data-grid-premium'
import {
  gridColumnDefinitionsSelector,
  gridExpandedSortedRowIdsSelector,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  gridSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import { FormikProvider, useFormik } from 'formik'
import { intersection } from 'lodash-es'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { validateFormValues } from '~/utils/formik'
import { Button } from '../Button'
import { useDataTableContext } from '../DataTableComposed/DataTableContext'
import { Menu } from '../Menu'
import { MenuItem } from '../Menu/MenuItem'
import { Modal } from '../Modal'
import { DataTableExportDialogSectionColumns } from './DataTableExportDialogSectionColumns'
import { DataTableExportDialogSectionDateRange } from './DataTableExportDialogSectionDateRange'
import { DataTableExportDialogSectionRows } from './DataTableExportDialogSectionRows'
import { validationSchema } from './helpers'
import type { DataTableExportDialogFormValues, DataTableExportDialogProps } from './types'

export const DataTableExportDialog = (props: DataTableExportDialogProps) => {
  const { apiRef } = useDataTableContext()
  const { t } = useTranslation()
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const visibleColumns = useGridSelector(apiRef, gridVisibleColumnFieldsSelector)
  const columnDefs = useGridSelector(apiRef, gridColumnDefinitionsSelector)

  const visibleAndExportableColumnFields = useMemo(() => {
    return intersection(
      visibleColumns,
      columnDefs.filter((column) => !column.disableExport).map((column) => column.field),
    )
  }, [visibleColumns, columnDefs])

  const formik = useFormik<DataTableExportDialogFormValues>({
    initialValues: {
      dateRange: 'today',
      columns: visibleAndExportableColumnFields,
      rows: 'all',
    },
    onSubmit: () => {
      // I guess nothing to do here...
    },
    validate: (values) => validateFormValues(values, validationSchema),
  })

  const handleClickExportBtn = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget)
  }

  const open = Boolean(anchor)
  const id = open ? 'simple-popper' : undefined

  const closeMenu = () => {
    setAnchor(null)
  }

  const getExportOptions = (): GridFileExportOptions => {
    const exportOptions: GridFileExportOptions = { fields: formik.values.columns }

    if (formik.values.rows === 'filtered') {
      exportOptions.getRowsToExport = ({ apiRef }) => gridExpandedSortedRowIdsSelector(apiRef)
    }

    if (formik.values.rows === 'unfiltered') {
      exportOptions.getRowsToExport = ({ apiRef }) => gridSortedRowIdsSelector(apiRef)
    }

    if (formik.values.rows === 'currentPage') {
      exportOptions.getRowsToExport = ({ apiRef }) =>
        gridPaginatedVisibleSortedGridRowIdsSelector(apiRef)
    }

    return exportOptions
  }

  const exportAsCSV = () => {
    apiRef.current.exportDataAsCsv(getExportOptions())
    closeMenu()
  }
  const exportAsExcel = () => {
    apiRef.current.exportDataAsExcel(getExportOptions())
    closeMenu()
  }
  const exportAsPrint = () => {
    apiRef.current.exportDataAsPrint(getExportOptions())
    closeMenu()
  }

  const menuItems = [
    {
      label: t('action.exportAs', { format: 'CSV' }),
      onClick: exportAsCSV,
    },
    {
      label: t('action.exportAs', { format: 'Excel' }),
      onClick: exportAsExcel,
    },
    {
      label: t('action.print'),
      onClick: exportAsPrint,
    },
  ]

  return (
    <FormikProvider value={formik}>
      <Modal.Root {...props.modalProps}>
        <Modal.Content>
          <div className="max-h-[90vh] w-[980px] max-w-full overflow-y-auto">
            <h2 className="text-subtitle-1 mb-6">{t('exportDialogTitle')}</h2>
            <div className="flex flex-col gap-4">
              {!props.hideExportDialogDateRangeSection && <DataTableExportDialogSectionDateRange />}
              {!props.hideExportDialogColumnsSection && <DataTableExportDialogSectionColumns />}
              <DataTableExportDialogSectionRows />
            </div>
            <div className="flex justify-end gap-2">
              <Button variant="secondary" onClick={props.onCancel}>
                {t('action.cancel')}
              </Button>

              <Button aria-describedby={id} onClick={handleClickExportBtn}>
                {t('action.export')}
              </Button>
              <BasePopup id={id} open={open} anchor={anchor} disablePortal>
                <Menu>
                  {menuItems.map((item) => (
                    <MenuItem key={item.label} onClick={item.onClick}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </BasePopup>
            </div>
          </div>
        </Modal.Content>
      </Modal.Root>
    </FormikProvider>
  )
}
