import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import { listWithKeyDateSearch } from '~/lib/searchSchemas'

const demandPromotionDetailsSearch = z
  .object({
    productDetails: z.record(z.string(), z.any()).optional(),
    keyColumnNames: z.record(z.string(), z.string()).optional(),
    groupFields: z.string().optional(),
  })
  .and(listWithKeyDateSearch)

export const Route = createFileRoute(
  '/_layout/planning/promotional-campaigns/$campaignId/promotion/$promotionId/details/',
)({
  validateSearch: demandPromotionDetailsSearch,
})
