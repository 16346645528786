import { createFileRoute } from '@tanstack/react-router'
import { redirectIfPathnamesMatch } from '~/utils/routing'

export const Route = createFileRoute('/_layout/administration/access-control/')({
  beforeLoad: ({ location }) => {
    redirectIfPathnamesMatch({
      pathname: location.pathname,
      expectedPathname: '/administration/access-control',
      redirectTo: '/administration/access-control/users',
    })
  },
})
