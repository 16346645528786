import type { DataGridPremiumProps } from '@mui/x-data-grid-premium'
import { colors } from '~/theme/colors'

export const baseStylesSx = {
  '& .MuiDataGrid-topContainer::after': {
    display: 'none', // to remove bottom line below the header row
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: colors.ghostwhite,
    border: 'none !important',
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
    borderBottom: `0.5px solid ${colors.night['100']}`,
    '&--textRight .MuiInputBase-input': {
      textAlign: 'right', // to align input text to right when column cell is right aligned
    },
  },
  '& .MuiDataGrid-container--bottom': {
    // i divided the scrollbarSize by 2 to fix the footer position, for some reason, it doens't consider that the scrollbarSize on the X asis is half of the size of the scrollbarSize on the Y axis
    bottom: 'calc( var(--DataGrid-hasScrollX) * ( var(--DataGrid-scrollbarSize) / 2) )',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    background: colors.white,
  },
  '& .MuiDataGrid-virtualScroller': {
    '& .MuiDataGrid-filler': {
      background: colors.white,
      border: 'none !important',
      '&--pinnedRight': {
        border: 'none',
      },
      '&--pinnedLeft': {
        border: 'none',
      },
      '& > div': {
        border: 'none',
      },
    },
  },
  '& .MuiDataGrid-row': {
    '&:hover': {
      background: colors.seasalt,
      '& .MuiDataGrid-cell--pinnedRight': {
        background: `${colors.seasalt} !important`,
      },
    },
    '&--borderBottom': {
      background: `${colors.ghostwhite} !important`,
      '& .MuiDataGrid-filler': {
        border: 'none',
        minHeight: '30px !important',
        backgroundColor: colors.ghostwhite,
      },
    },
  },
} satisfies DataGridPremiumProps['sx']
