import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import { listWithKeyDateSearch } from '~/lib/searchSchemas'

const scenarioCreationSearchParams = z
  .object({
    name: z.string().min(3),
    description: z.string().optional(),
  })
  .and(listWithKeyDateSearch)

export type ScenarioCreationSearchParams = z.infer<typeof scenarioCreationSearchParams>

export const Route = createFileRoute('/_layout/planning/scenarios/create/')({
  validateSearch: scenarioCreationSearchParams,
})
