import type { OptionProps as BaseOptionProps } from '@mui/base'
import { Option as BaseOption } from '@mui/base'
import clsx from 'clsx/lite'

export const SelectOption = (props: BaseOptionProps<number | string>) => {
  return (
    <BaseOption
      {...props}
      slotProps={{
        root: ({ selected, disabled }) => ({
          className: clsx(
            'w-full list-none p-2',
            disabled
              ? 'cursor-default text-night-400'
              : 'cursor-pointer hover:bg-seasalt focus-visible:outline focus-visible:outline-1 focus-visible:outline-azure',
            selected && 'bg-azure/10 text-azure',
          ),
        }),
      }}
    />
  )
}
