import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import { viewModes } from '~/pages/Planning/PromotionalCalendar/types'

const promotionalCalendarSearch = z.object({
  viewMode: z.enum(viewModes).catch('monthly'),
})

export type PromotionalCalendarSearch = z.infer<typeof promotionalCalendarSearch>

export const Route = createFileRoute('/_layout/planning/promotional-calendar/')({
  validateSearch: promotionalCalendarSearch,
})
