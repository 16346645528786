import clsx from 'clsx'
import type { OptionalClassName } from '~/types'
import { useDataTableContext } from './DataTableContext'

interface DataTableBoxProps extends OptionalClassName {
  children: React.ReactNode
  disableContainerPadding?: boolean
  disableContainerBorder?: boolean
}

export const DataTableBox = (props: DataTableBoxProps) => {
  const {
    rootProps: { pagination },
  } = useDataTableContext()

  const hasPagination = !!pagination

  return (
    <div
      data-table-id="data-table-box"
      className={clsx(
        'flex min-h-[300px] flex-col bg-white',
        !props.disableContainerPadding && 'p-6',
        !props.disableContainerBorder && 'rounded-lg border border-night-100 shadow-card',
        hasPagination ? 'h-[calc(100%-36px-28px)]' : 'h-full', // 36px → pagination component height, 28px → space between the table and the pagination comp.
        props.className,
      )}
    >
      {props.children}
    </div>
  )
}
