import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '~/components/UI/Button'
import { Loader } from '~/components/UI/Loader'
import { Modal } from '~/components/UI/Modal'
import { useFiltersSetDeleteMutation } from '~/hooks/mutations/useFiltersSetDeleteMutation'
import { useSavedFiltersSetsQuery } from '~/hooks/queries'
import { useFilterSetById } from '~/hooks/queries/useFilterSetById'
import { useAuthContext } from '~/providers/AuthProvider'
import type { Filter } from '~/providers/FiltersProvider'
import { FilterSetSource } from '~/types/apiContracts'
import { FiltersSetChip } from './FiltersSetChip'
import type { FiltersSetsProps } from './types'

export const FiltersSets = (props: FiltersSetsProps) => {
  const { t } = useTranslation(['common', 'filter'])
  const { userId } = useAuthContext()

  const savedFiltersSetQuery = useSavedFiltersSetsQuery(props.moduleId, userId)
  const {
    mutateAsync: deleteFiltersSetMutation,
    isPending,
    variables,
  } = useFiltersSetDeleteMutation()

  const [filterToDelete, setFilterToDelete] = useState<{
    filtersSetName: string
    filterSetId: number | undefined
  }>({
    filtersSetName: '',
    filterSetId: undefined,
  })

  const { mutateAsync: getFilterSetById } = useFilterSetById()

  const handleDelete = async () => {
    await deleteFiltersSetMutation(
      {
        moduleId: props.moduleId,
        filtersSetName: filterToDelete.filtersSetName,
        filterSetId: filterToDelete.filterSetId,
      },
      {
        onSuccess: () => {
          setFilterToDelete({
            filtersSetName: '',
            filterSetId: undefined,
          })
        },
      },
    )
  }

  const handleSetFilterSet = async (filterSetId: number) => {
    const data = await getFilterSetById(filterSetId)

    props.onClickFilterSet(
      data.filter_set.filters.map(({ operator, value, column }) => {
        return {
          id: column.id,
          operator: operator,
          value: value.map((value) => {
            return {
              label: value,
              value: value,
            }
          }),
          field: column.id,
        }
      }) as unknown as Filter[],
    )
  }

  return (
    <div className="mt-10 flex flex-wrap gap-1.5">
      {savedFiltersSetQuery.isLoading && (
        <div className="flex items-center gap-1">
          <Loader iconSize={25} />
          <span> {t('status.loading')}</span>
        </div>
      )}
      {savedFiltersSetQuery.isError && <div>{t('status.error')}</div>}
      {savedFiltersSetQuery.isSuccess && (
        <>
          {savedFiltersSetQuery.data?.items?.length === 0 && <div>{t('noSavedFilters')}</div>}
          {savedFiltersSetQuery.data?.items?.map((filtersSet) => {
            const isDeletingFiltersSet = isPending && variables?.filtersSetName === filtersSet.name

            return (
              <FiltersSetChip
                key={filtersSet.id}
                label={filtersSet.name}
                onClick={() => handleSetFilterSet(filtersSet.id)}
                onDelete={() =>
                  setFilterToDelete({
                    filtersSetName: filtersSet.name,
                    filterSetId: filtersSet.id,
                  })
                }
                disabled={isDeletingFiltersSet}
                loading={isDeletingFiltersSet}
                isUserSource={filtersSet.source === FilterSetSource.USER}
              />
            )
          })}

          <Modal.Root open={!!filterToDelete.filtersSetName} className="px-20 py-10">
            <Modal.Content>
              <div className="flex flex-col items-center gap-5">
                <h2 className="text-subtitle-2-strong">
                  {t('action.delete')} {t('filter')}
                </h2>
                <span>
                  {t('filter:actions.confirmFilterDeletion', {
                    filterName: filterToDelete.filtersSetName.trim(),
                  })}
                </span>
                <div className="flex items-center gap-8">
                  <Button
                    variant="secondary"
                    onClick={() =>
                      setFilterToDelete({
                        filtersSetName: '',
                        filterSetId: undefined,
                      })
                    }
                  >
                    {t('action.cancel')}
                  </Button>
                  <Button onClick={handleDelete}>{t('action.delete')}</Button>
                </div>
              </div>
            </Modal.Content>
          </Modal.Root>
        </>
      )}
    </div>
  )
}
