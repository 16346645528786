import { createFileRoute } from '@tanstack/react-router'
import { z } from 'zod'
import { listWithKeyDateSearch } from '~/lib/searchSchemas'
import { demandPromotionSearch } from '~/pages/Planning/DemandAndPromotions/common/helpers'

const demandPromotionDetailsSearch = z
  .object({
    productDetails: z.record(z.string(), z.any()),
    keyColumnNames: z.record(z.string(), z.string()),
    promotionId: z.string().optional(),
    groupFields: z.string().optional(),
  })
  .and(demandPromotionSearch)
  .and(listWithKeyDateSearch)

export const Route = createFileRoute('/_layout/planning/demand-and-promotions/details/')({
  validateSearch: demandPromotionDetailsSearch,
})
