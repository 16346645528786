import clsx from 'clsx'
import { CheckboxInput } from '~/components/UI/Checkbox/CheckboxInput'
import type { CheckboxInputProps } from '~/components/UI/Checkbox/types'

interface DataTableColumnsPanelItemProps extends CheckboxInputProps {
  rootClassName?: string
  label?: string
}

export const DataTableColumnsPanelItem = (props: DataTableColumnsPanelItemProps) => {
  const { label, rootClassName, ...rest } = props

  return (
    // biome-ignore lint/a11y/noLabelWithoutControl: the input is within the CheckboxInput component
    <label
      className={clsx(
        'flex h-8 items-center gap-2.5 px-3 [&:not(:has(:disabled)):hover]:bg-seasalt [&:not(:has(:disabled))]:cursor-pointer',
        rootClassName,
      )}
    >
      <CheckboxInput {...rest} />
      <span className="text-caption-1 flex-grow text-night-700">{label}</span>
    </label>
  )
}
