import { useQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash-es'
import { api } from '~/lib/api'
import { TENANTS_PERMISSIONS } from '~/lib/queryKeys'
import { convertModuleCodeToUrl } from '~/providers/AuthProvider/helpers'
import type { ApiResponse, Permission, TenantUserRolesResponse } from '~/types/apiContracts'

async function fetchTenantPermissions(): Promise<TenantUserRolesResponse> {
  const res = (await api.get('configs/auth/me').json()) as ApiResponse<TenantUserRolesResponse>
  return res.data
}

type UseTenantUserRolesPermissionsQueryOptions = {
  enabled: boolean
}

export type TenantUserRolesPermissionsData = {
  userGlobalId: string
  user_id: number
  landingUrl: string
  permissions: Permission[]
}

export function useTenantUserRolesPermissionsQuery({
  enabled,
}: UseTenantUserRolesPermissionsQueryOptions) {
  return useQuery({
    queryKey: [TENANTS_PERMISSIONS],
    queryFn: () => fetchTenantPermissions(),
    enabled,
    select: (data): TenantUserRolesPermissionsData => {
      if (!data.user.active) {
        return {
          userGlobalId: data.user.global_id,
          user_id: data.user.id,
          landingUrl: '',
          permissions: [],
        }
      }

      const roles = data.user.roles || []

      let landingUrl = ''
      let currentPriority = 0
      let permissions: Permission[] = []

      roles.forEach((role) => {
        if (role.priority > currentPriority) {
          currentPriority = role.priority
          landingUrl = role.landing_url
        }
        if (role.permissions) {
          permissions = [...permissions, ...role.permissions]
        }
      })

      landingUrl = convertModuleCodeToUrl(landingUrl)

      const uniquePermissions = uniqBy(permissions, (permission) => permission.code)

      return {
        userGlobalId: data.user.global_id,
        user_id: data.user.id,
        landingUrl,
        permissions: uniquePermissions,
      }
    },
  })
}
