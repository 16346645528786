/**
 * ⚠️ ⚠️ ⚠️
 *
 * THIS COMPONENT IS OUR OWN VERSION OF THE 'GridColumnsManagement' FROM 'mui-x' REPOSITORY.
 *
 * WE TOOK ALMOST ALL THE CODE FROM THE ORIGINAL COMPONENT, WITH SOME SMALL MODIFICATIONS
 * TO MAKE IT WORK WITH OUR 'DataTable' COMPONENT.
 *
 * SEE: https://github.com/mui/mui-x/blob/master/packages/x-data-grid/src/components/columnsManagement/GridColumnsManagement.tsx
 *
 * ⚠️ ⚠️ ⚠️
 */
import {
  type GridColDef,
  gridColumnDefinitionsSelector,
  gridColumnVisibilityModelSelector,
  useGridSelector,
} from '@mui/x-data-grid'
import type { GridColumnVisibilityModel, GridColumnsManagementProps } from '@mui/x-data-grid'
import { IconSearch, IconX } from '@tabler/icons-react'
import clsx from 'clsx'
import { isEmpty } from 'lodash-es'
import { type ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button } from '~/components/UI/Button'
import { IconButton } from '../../IconButton'
import { Input } from '../../Input'
import { useDataTableContext } from '../DataTableContext'
import { DataTableColumnsPanelItem } from './DataTableColumnsPanelItem'
import { useLazyRef } from './utils'

export const DataTableColumnsPanel = () => {
  const { apiRef } = useDataTableContext()

  if (isEmpty(apiRef.current)) return null

  return <DataTableColumnsPanelInner />
}

interface DataTableColumnsPanelProps extends GridColumnsManagementProps {}

const collator = new Intl.Collator()

const DataTableColumnsPanelInner = (props: DataTableColumnsPanelProps) => {
  const { apiRef } = useDataTableContext()

  const searchInputRef = useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = useState('')

  const initialColumnVisibilityModel = useLazyRef(() =>
    gridColumnVisibilityModelSelector(apiRef),
  ).current
  const columnVisibilityModel = useGridSelector(apiRef, gridColumnVisibilityModelSelector)

  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector)

  const {
    sort,
    searchPredicate = defaultSearchPredicate,
    autoFocusSearchField = true,
    disableShowHideToggle = false,
    disableResetButton = false,
    toggleAllMode = 'all',
    getTogglableColumns,
    // searchInputProps,
  } = props

  const isResetDisabled = useMemo(
    () => checkColumnVisibilityModelsSame(columnVisibilityModel, initialColumnVisibilityModel),
    [columnVisibilityModel, initialColumnVisibilityModel],
  )

  const sortedColumns = useMemo(() => {
    switch (sort) {
      case 'asc':
        return [...columns].sort((a, b) =>
          collator.compare(a.headerName || a.field, b.headerName || b.field),
        )

      case 'desc':
        return [...columns].sort(
          (a, b) => -collator.compare(a.headerName || a.field, b.headerName || b.field),
        )

      default:
        return columns
    }
  }, [columns, sort])

  const toggleColumn = (columnField: string) => {
    apiRef.current.setColumnVisibility(columnField, columnVisibilityModel[columnField] === false)
  }

  const currentColumns = useMemo(() => {
    const togglableColumns = getTogglableColumns ? getTogglableColumns(sortedColumns) : null

    const togglableSortedColumns = togglableColumns
      ? sortedColumns.filter(({ field }) => togglableColumns.includes(field))
      : sortedColumns

    if (!searchValue) {
      return togglableSortedColumns
    }

    return togglableSortedColumns.filter((column) =>
      searchPredicate(column, searchValue.toLowerCase()),
    )
  }, [sortedColumns, searchValue, searchPredicate, getTogglableColumns])

  const toggleAllColumns = useCallback(
    (isVisible: boolean) => {
      const currentModel = gridColumnVisibilityModelSelector(apiRef)
      const newModel = { ...currentModel }
      const togglableColumns = getTogglableColumns ? getTogglableColumns(columns) : null

      ;(toggleAllMode === 'filteredOnly' ? currentColumns : columns).forEach((col) => {
        if (col.hideable && (togglableColumns == null || togglableColumns.includes(col.field))) {
          if (isVisible) {
            // delete the key from the model instead of setting it to `true`
            delete newModel[col.field]
          } else {
            newModel[col.field] = false
          }
        }
      })

      return apiRef.current.setColumnVisibilityModel(newModel)
    },
    [apiRef, columns, getTogglableColumns, toggleAllMode, currentColumns],
  )

  const handleSearchValueChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }, [])

  const handleSearchReset = useCallback(() => {
    setSearchValue('')
    searchInputRef.current?.focus()
  }, [])

  const hideableColumns = useMemo(
    () => currentColumns.filter((col) => col.hideable),
    [currentColumns],
  )

  const allHideableColumnsVisible = useMemo(
    () =>
      hideableColumns.every(
        (column) =>
          columnVisibilityModel[column.field] == null ||
          columnVisibilityModel[column.field] !== false,
      ),
    [columnVisibilityModel, hideableColumns],
  )

  const allHideableColumnsHidden = useMemo(
    () => hideableColumns.every((column) => columnVisibilityModel[column.field] === false),
    [columnVisibilityModel, hideableColumns],
  )

  const firstSwitchRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autoFocusSearchField) {
      searchInputRef.current?.focus()
    } else if (firstSwitchRef.current && typeof firstSwitchRef.current.focus === 'function') {
      firstSwitchRef.current?.focus()
    }
  }, [autoFocusSearchField])

  let firstHideableColumnFound = false
  const isFirstHideableColumn = (column: GridColDef) => {
    if (firstHideableColumnFound === false && column.hideable !== false) {
      firstHideableColumnFound = true
      return true
    }
    return false
  }

  return (
    <div className="bg-white">
      <div className="mx-3 my-3">
        <Input
          placeholder={apiRef.current.getLocaleText('columnsManagementSearchTitle')}
          ref={searchInputRef}
          value={searchValue}
          onChange={handleSearchValueChange}
          variant="outlined"
          size="small"
          type="search"
          startAdornment={
            <IconButton>
              <IconSearch size={12} />
            </IconButton>
          }
          endAdornment={
            <IconButton
              aria-label={apiRef.current.getLocaleText('columnsManagementDeleteIconLabel')}
              className={clsx(!searchValue && 'hidden')}
              tabIndex={-1}
              onClick={handleSearchReset}
            >
              <IconX size={12} />
            </IconButton>
          }
          aria-label={apiRef.current.getLocaleText('columnsManagementSearchTitle')}
          autoComplete="off"
          className="w-full"
        />
      </div>

      {currentColumns.length > 0 && (
        <ol className="max-h-80 overflow-y-auto">
          {currentColumns.map((column) => {
            return (
              <li key={column.field}>
                <DataTableColumnsPanelItem
                  checked={columnVisibilityModel[column.field] !== false}
                  onChange={() => toggleColumn(column.field)}
                  disabled={!column.hideable}
                  label={column.headerName}
                  ref={isFirstHideableColumn(column) ? firstSwitchRef : undefined}
                />
              </li>
            )
          })}
        </ol>
      )}
      {currentColumns.length === 0 && (
        <div className="text-caption-1 text-night-700">
          {apiRef.current.getLocaleText('columnsManagementNoColumns')}
        </div>
      )}

      {(!disableShowHideToggle || !disableResetButton) && currentColumns.length > 0 && (
        <div className="flex items-center border-t border-night-700">
          {!disableShowHideToggle && (
            <DataTableColumnsPanelItem
              checked={allHideableColumnsVisible}
              onChange={() => toggleAllColumns(!allHideableColumnsVisible)}
              indeterminate={!allHideableColumnsVisible && !allHideableColumnsHidden}
              disabled={hideableColumns.length === 0}
              label={apiRef.current.getLocaleText('columnsManagementShowHideAllText')}
              rootClassName="flex-grow"
            />
          )}

          {!disableResetButton && (
            <Button
              onClick={() => apiRef.current.setColumnVisibilityModel(initialColumnVisibilityModel)}
              disabled={isResetDisabled}
              variant="primary-text"
            >
              {apiRef.current.getLocaleText('columnsManagementReset')}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

const checkColumnVisibilityModelsSame = (
  a: GridColumnVisibilityModel,
  b: GridColumnVisibilityModel,
) => {
  // Filter `false` values only, as `true` and not having a key are the same
  const aFalseValues = new Set(Object.keys(a).filter((key) => a[key] === false))
  const bFalseValues = new Set(Object.keys(b).filter((key) => b[key] === false))
  if (aFalseValues.size !== bFalseValues.size) {
    return false
  }

  let result = true
  aFalseValues.forEach((key) => {
    if (!bFalseValues.has(key)) {
      result = false
    }
  })
  return result
}

const defaultSearchPredicate: NonNullable<GridColumnsManagementProps['searchPredicate']> = (
  column,
  searchValue,
) => (column.headerName || column.field).toLowerCase().indexOf(searchValue) > -1
