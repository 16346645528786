import { z } from 'zod'
import { getTranslatedWeekDayCodesMap } from '~/lib/builtInTranslations'
import type { FilterFieldOption, FilterOptionDatatype } from '~/types/apiContracts'
import type { BasicOption } from '~/types/shared'

export const filterValidationSchema = z
  .object({
    id: z.union([z.string(), z.number()]),
    field: z.string(),
    operator: z.string(),
    value: z.union([z.array(z.any()), z.number().nonnegative()]).optional(),
  })
  .refine((data) => {
    if (['in', 'not in'].includes(data.operator) && Array.isArray(data.value)) {
      return data.value && data.value.length > 0
    }
    return true
  })

export const validationSchema = z.object({
  filters: z.array(filterValidationSchema).min(1),
})

export const getSelectedFieldOptions = (
  datatype: FilterOptionDatatype,
  fieldOptions: FilterFieldOption[],
): BasicOption[] => {
  if (datatype === 'WEEK_DAYS') {
    const translatedWeekDayCodesMap = getTranslatedWeekDayCodesMap()

    return Object.entries(translatedWeekDayCodesMap).map(([code, label]) => ({
      label: label,
      value: code,
    }))
  }

  return fieldOptions.map(({ value }) => ({
    label: value,
    value,
  }))
}

const createModeOperatorMap: Record<string, string> = {
  'is null': 'is empty',
  'is not null': 'is not empty',
}

const viewModeOperatorMap: Record<string, string> = {
  'is empty': 'is null',
  'is not empty': 'is not null',
}

export const convertOperatorByMode = (operator: string, isCreateMode = false) => {
  const map = isCreateMode ? createModeOperatorMap : viewModeOperatorMap
  return map[operator] ?? operator
}
