import type { DataGridPremiumProps, GridColDef, GridInitialState } from '@mui/x-data-grid-premium'
import { gridPinnedColumnsSelector, useGridApiRef } from '@mui/x-data-grid-premium'
import clsx from 'clsx/lite'
import { difference, isEmpty } from 'lodash-es'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocalStorage, usePrevious } from 'react-use'
import { NoData } from '~/components/NoData'
import { arraysHaveSameItems, moveArrayElement } from '~/utils/array'
import { omit } from '~/utils/general'
import { Loader } from '../Loader'
import { DataTableContext } from './DataTableContext'
import { DataTableRowsSekeleton } from './DataTableRowsSekeleton'
import {
  checkIfColumnsAreEmpty,
  consolidateColumnFields,
  consolidateColumnWidths,
  consolidateColumnsFieldsWithGroupModel,
  dataTableCustomPropsKeys,
  getBaseLocaleText,
  getDataGridStateFromLocalStorage,
  mapColumnWidths,
  saveDataGridStateToLocalStorage,
} from './helpers'
import { baseStylesSx } from './styles'
import type { DataTableRootProps } from './types'
import { useDataTableScrollAnimation } from './useDataTableScrollAnimation'

export const DataTableRoot = (props: DataTableRootProps) => {
  if (!props.tableId) {
    throw new Error('tableId prop cannot be empty.')
  }
  const apiLocalRef = useGridApiRef()

  const columns = props.columns

  const columnsFields = useMemo(() => columns.map((col) => col.field), [columns])
  const [columnFieldsOrdered, setColumnFieldsOrdered] = useLocalStorage(
    `datatable-column-fields-ordered.${props.tableId}`,
    columns.map((col) => col.field),
  )
  const [columnWidths, setColumnWidths] = useLocalStorage(
    `datatable-column-widths.${props.tableId}`,
    mapColumnWidths(columns),
  )

  const [initialState, setInitialState] = useState<GridInitialState | undefined>(
    // if table state is going to load from localStorage, we will make it undefined until we load it
    props.enableStatePersistence ? undefined : props.initialState || {},
  )
  const hasLoadedInitalState = !!initialState

  const { t } = useTranslation()

  const apiRef = props.apiRef || apiLocalRef
  const areColumnsEmpty = checkIfColumnsAreEmpty(columns)

  useDataTableScrollAnimation({ disable: props.disableScrollAnimation })

  useEffect(() => {
    if (areColumnsEmpty) return

    if (columnFieldsOrdered && arraysHaveSameItems(columnFieldsOrdered, columnsFields)) return

    // in case the number of column fields differ, generate the orderedColumnFields again
    const newColumnFieldsOrdered = props.columnGroupingModel
      ? consolidateColumnsFieldsWithGroupModel(columnsFields, props.columnGroupingModel)
      : columnFieldsOrdered
        ? consolidateColumnFields(columnFieldsOrdered, columnsFields)
        : columnsFields
    const newColumnWidths = columnWidths
      ? consolidateColumnWidths(columnWidths, mapColumnWidths(columns))
      : mapColumnWidths(columns)

    setColumnFieldsOrdered(newColumnFieldsOrdered)
    setColumnWidths(newColumnWidths)
  }, [
    areColumnsEmpty,
    columnFieldsOrdered,
    columns,
    columnWidths,
    columnsFields,
    setColumnFieldsOrdered,
    setColumnWidths,
    props.columnGroupingModel,
  ])

  const mappedColumns = useMemo(() => {
    if (!columnFieldsOrdered || !columnWidths) return columns

    const mappedColumnsTemp = columnFieldsOrdered.reduce<GridColDef[]>((arr, field) => {
      let column = columns.find((col) => col.field === field)

      if (!column) return arr

      const columnWidth = columnWidths[column.field]
      const hasColumnWidth = !!columnWidth

      const originalCellClassName = column.cellClassName

      column = {
        ...column,
        ...(hasColumnWidth && {
          width: columnWidth,
          flex: undefined,
          // unset the flex property
        }),
        cellClassName: (params) => {
          const isCellClassNameFunction = typeof originalCellClassName === 'function'

          const className = isCellClassNameFunction
            ? originalCellClassName(params)
            : originalCellClassName

          return clsx(className, column?.type === 'number' && 'text-caption-2')
        },
      }

      arr.push(column)

      return arr
    }, [])

    return mappedColumnsTemp
  }, [columnFieldsOrdered, columnWidths, columns])

  const prevMappedColumns = usePrevious(mappedColumns)

  const saveStateToLocalStorage = useCallback(() => {
    saveDataGridStateToLocalStorage(props.tableId, apiRef)
  }, [props.tableId, apiRef])

  useLayoutEffect(() => {
    if (!props.enableStatePersistence) return

    if (!hasLoadedInitalState) {
      const stateFromLocalStorage = getDataGridStateFromLocalStorage(props.tableId)

      const newInitialState = stateFromLocalStorage || props.initialState || {}

      // This ensures that the change of pinnedColumns is reactive
      setInitialState({
        ...newInitialState,
        pinnedColumns: newInitialState.pinnedColumns || props.initialState?.pinnedColumns,
      })
      return
    }

    window.addEventListener('beforeunload', saveStateToLocalStorage)

    return () => {
      window.removeEventListener('beforeunload', saveStateToLocalStorage)
      saveStateToLocalStorage()
    }
  }, [
    hasLoadedInitalState,
    props.initialState,
    props.tableId,
    saveStateToLocalStorage,
    props.enableStatePersistence,
  ])

  if (!hasLoadedInitalState) {
    return <Loader />
  }

  const hasNoData = !props.loading && !props.rows?.length

  if (hasNoData) {
    return (
      <div
        className={clsx(
          'h-full rounded-lg border border-night-100 bg-white px-6 py-2 shadow-card',
          props.noDataContainerClassName,
        )}
        data-testid="DataTable-noData"
      >
        <NoData />
      </div>
    )
  }

  const hasAnyAggregation = !isEmpty(props.aggregationModel)

  const handleSortModelChange: DataTableRootProps['onSortModelChange'] = (sortModel, details) => {
    // Prevent updating the sort model if the columns are empty or
    // if the mapped columns were just generated (by checking if the previous mapped columns are empty)
    if (areColumnsEmpty || (prevMappedColumns && checkIfColumnsAreEmpty(prevMappedColumns))) return

    props.onSortModelChange?.(sortModel, details)
  }

  const handleColumnOrderChange: DataTableRootProps['onColumnOrderChange'] = (params) => {
    if (!columnFieldsOrdered) return

    const oldIndex = props.checkboxSelection ? params.oldIndex - 1 : params.oldIndex
    const targetIndex = props.checkboxSelection ? params.targetIndex - 1 : params.targetIndex

    const pinnedColumns = gridPinnedColumnsSelector(apiRef.current.state)
    const rightPinnedColumns = pinnedColumns.right
    const hasRightPinnedColumns = rightPinnedColumns && rightPinnedColumns.length > 0

    const columnFieldsOrderedClone = hasRightPinnedColumns
      ? // strip right pinned columns out to prevent bug affecting the ordering of the columns
        difference(columnFieldsOrdered, rightPinnedColumns)
      : [...columnFieldsOrdered]

    const newColumnFieldsOrdered = moveArrayElement(columnFieldsOrderedClone, oldIndex, targetIndex)

    if (hasRightPinnedColumns) {
      // re-add right pinned columns to the end of the newColumnFieldsOrdered
      rightPinnedColumns.forEach((rightPinnedColumn) => {
        newColumnFieldsOrdered.push(rightPinnedColumn)
      })
    }

    setColumnFieldsOrdered(newColumnFieldsOrdered)
  }

  const handleColumnWidthChange: DataTableRootProps['onColumnWidthChange'] = (params) => {
    setColumnWidths({
      ...columnWidths,
      [params.colDef.field]: params.width,
    })
  }

  const dataGridRootProps = omit(props, ...dataTableCustomPropsKeys)

  const dataGridProps: DataGridPremiumProps = {
    ...dataGridRootProps,
    apiRef,
    columns: mappedColumns,
    slots: {
      loadingOverlay: props.isLoadingFirstTime ? DataTableRowsSekeleton : undefined,
      ...props.slots,
      toolbar: null,
    },
    localeText: {
      ...getBaseLocaleText(t),
      ...props.localeText,
    },
    slotProps: {
      // TODO: check if we can remove this since footer is now a separate component
      footer: {
        style: {
          minHeight: '24px',
          borderTop: 'none',
        },
      },
      ...props.slotProps,
    },
    initialState,
    sx: {
      ...baseStylesSx,
      ...props.sx,
    },
    classes: {
      root: 'overflow-hidden !border-0',
      main: 'text-caption-1',
      columnHeaderTitle: '!text-caption-1',
      columnHeaderTitleContainerContent: 'text-night-600',
      virtualScrollerContent: 'text-night-700',
    },
    scrollbarSize: 0,
    rowHeight: 42,
    columnHeaderHeight: hasAnyAggregation ? 44 : 32,
    hideFooter: true,
    loading: props.isLoadingFirstTime || props.loading,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnWidthChange: handleColumnWidthChange,
    sortModel: areColumnsEmpty ? undefined : props.sortModel,
    onSortModelChange: areColumnsEmpty ? undefined : handleSortModelChange,
    disableMultipleColumnsSorting: dataGridRootProps.disableMultipleColumnsSorting ?? true,
  }

  return (
    <DataTableContext.Provider
      value={{
        tableId: props.tableId,
        apiRef,
        rootProps: props,
        dataGridRootProps,
        dataGridProps,
      }}
    >
      {props.children}
    </DataTableContext.Provider>
  )
}
