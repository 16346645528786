import clsx from 'clsx/lite'
import { CheckboxInput } from './CheckboxInput'
import type { CheckboxProps } from './types'

export const Checkbox = function Checkbox(props: CheckboxProps) {
  const {
    className,
    inputClassName,
    labelClassName,
    checkboxClassName,
    label,
    indeterminate,
    ...inputProps
  } = props

  return (
    // biome-ignore lint/a11y/noLabelWithoutControl: doesn't apply here
    <label className={clsx('inline-flex cursor-pointer items-center gap-2', className)}>
      <CheckboxInput
        {...inputProps}
        indeterminate={indeterminate}
        className={inputClassName}
        wrapperClassName={checkboxClassName}
      />

      <span className={clsx('text-body-2', labelClassName)}>{label}</span>
    </label>
  )
}
