import { Button as BaseButton } from '@mui/base/Button'
import clsx from 'clsx/lite'
import type { IconButtonProps } from './types'

// deprecated
/**
 * Used for simple icon buttons. Currently for the DataTable row actions on Procurement products table
 * @todo For most of cases, use the `Button` component instead with the `icon` prop.
 */
export const IconButton = (props: IconButtonProps) => {
  return (
    <BaseButton
      {...props}
      className={clsx('rounded-md text-night-600 hover:bg-seasalt', props.className)}
    >
      {props.children}
    </BaseButton>
  )
}
