import type { MenuItemProps } from '@mui/base/MenuItem'
import { MenuItem as BaseMenuItem } from '@mui/base/MenuItem'
import clsx from 'clsx/lite'

export const MenuItem = (props: MenuItemProps) => {
  const { className, ...other } = props
  return (
    <BaseMenuItem
      className={clsx(
        'flex items-center p-3',
        props.onClick && 'select-none focus:bg-night-100 focus:text-night-800 focus:outline-0',
        props.disabled ? 'text-night-300' : 'cursor-pointer hover:bg-seasalt',
        className,
      )}
      {...other}
    />
  )
}
