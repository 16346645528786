import { useMenu } from '@mui/base'
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton'
import { IconCaretDownFilled, IconChevronDown } from '@tabler/icons-react'
import clsx from 'clsx/lite'
import type { MenuButtonProps } from './types'

export const MenuButton = (props: MenuButtonProps) => {
  const { className, variant = 'contained', slotProps, ...other } = props

  const { show: shouldShowIcon = true, variant: iconVariant = 'chevron' } = slotProps?.icon || {}

  const { open } = useMenu()

  const iconClassName = clsx(
    'flex-shrink-0 transition-all duration-200',
    open && 'rotate-180',
    props?.slotProps?.icon?.className,
  )

  return (
    <BaseMenuButton
      className={clsx(
        'flex cursor-pointer items-center justify-between text-sm transition-all duration-200 focus-visible:outline-none',
        variant === 'contained' && 'rounded-xl px-4 py-2 text-neutral-900 hover:bg-night-50',
        className,
      )}
      {...other}
    >
      <span>{props.children}</span>

      {shouldShowIcon &&
        (iconVariant === 'chevron' ? (
          <IconChevronDown className={iconClassName} />
        ) : (
          <IconCaretDownFilled className={iconClassName} />
        ))}
    </BaseMenuButton>
  )
}
