/**
 * BASED ON https://github.com/mui/mui-x/blob/master/packages/x-data-grid/src/components/GridRowCount.tsx.
 */
import {
  gridFilteredTopLevelRowCountSelector,
  gridTopLevelRowCountSelector,
  useGridSelector,
} from '@mui/x-data-grid'
import clsx from 'clsx'
import type { OptionalClassName } from '~/types'
import { useDataTableContext } from './DataTableContext'

export const DataTableRowCount = (props: OptionalClassName) => {
  const { apiRef } = useDataTableContext()

  const rowCount = useGridSelector(apiRef, gridTopLevelRowCountSelector)
  const visibleRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector)

  if (rowCount === 0) {
    return null
  }

  const text =
    visibleRowCount < rowCount
      ? apiRef.current.getLocaleText('footerTotalVisibleRows')(visibleRowCount, rowCount)
      : rowCount.toLocaleString()

  return (
    <div
      data-table-id="data-table-rowcount"
      className={clsx('text-body-2 p-2 text-night-700', props.className)}
    >
      {apiRef.current.getLocaleText('footerTotalRows')} {text}
    </div>
  )
}
