import { Outlet, createRootRouteWithContext, redirect, useMatches } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import type { RouterContext } from '~/types/routerContext'
import type { RoutePath } from '~/types/shared'
import { getPageTitleFromPath } from '~/utils/routing'

const checkIfIsPublicRoute = (pathname: string) => {
  const publicRoutes: RoutePath[] = ['/login', '/logout']
  return publicRoutes.includes(pathname as RoutePath)
}

const RootComponent = () => {
  const { ready } = useTranslation('routes')
  const matches = useMatches()

  const pageTitle = useMemo(() => {
    const defaultTitle = 'Celes App'
    const separator = ' · '

    if (!ready) return defaultTitle

    const segmentTitles = matches
      .toReversed()
      .map((match) => match.pathname)
      .filter((value) => value !== '/')
      .map((pathname) => getPageTitleFromPath(pathname) || `(${t('pageNotFound')})`)

    segmentTitles.push(defaultTitle)

    return segmentTitles.join(separator)
  }, [ready, matches])

  useEffect(() => {
    document.title = pageTitle
  }, [pageTitle])

  return (
    <>
      <Outlet />
      {import.meta.env.VITE_ENABLE_TANSTACK_ROUTER_DEVTOOLS === 'true' && (
        <TanStackRouterDevtools />
      )}
    </>
  )
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  beforeLoad: ({ context, location }) => {
    const isPublicRoute = checkIfIsPublicRoute(location.pathname)
    const shouldRedirect = !isPublicRoute && !context.auth.isAuthenticatedWithTenant

    if (shouldRedirect) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
        replace: true,
      })
    }
  },
})
