import { CssTransition } from '@mui/base'
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup'
import type { DateRange, RangePosition } from '@mui/x-date-pickers-pro'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import { IconX } from '@tabler/icons-react'
import clsx from 'clsx'
import { subDays } from 'date-fns'
import { isEqual } from 'lodash-es'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatShortDate } from '~/utils/date'
import { Button } from '../UI/Button'
import { DateRangePickerFilterHeader } from './DateRangePickerFilterHeader'
import { useDateRangePickerFilterContext } from './DateRangePickerFilterProvider'
import { dateRangePickerFilterSlotProps } from './helpers'
import type { DateRangePickerFilterComparisonProps } from './types'

export const DateRangePickerFilterComparison = (props: DateRangePickerFilterComparisonProps) => {
  const { isOpen, popupId, popupAnchor, onBackClick, onSave, ...rest } = props
  const { t } = useTranslation()

  const { saveComparisonDateRange, defaultValues, values, resetComparisonDates } =
    useDateRangePickerFilterContext()

  const [internalValue, setInternalValue] = useState<DateRange<Date>>(defaultValues.comparison)
  const [minimalComparationDate] = defaultValues.main

  const [rangePosition, setRangePosition] = useState<RangePosition>('start')

  const handleMonthSelect = (dates: DateRange<Date>, rangePosition: RangePosition) => {
    setRangePosition(rangePosition)
    setInternalValue(dates)
  }

  const handleSaveDates = () => {
    setRangePosition('start')
    saveComparisonDateRange(internalValue)
    onSave()
  }

  const hasComparisonDates = !isEqual(values.comparison, defaultValues.comparison)

  const isDiferentFromInitial = !isEqual(internalValue, defaultValues.comparison)

  const handleResetDates = () => {
    resetComparisonDates()
    setInternalValue(defaultValues.comparison)
  }

  const formattedComparisonDates = values.comparison
    .map((date) => formatShortDate(date as Date))
    .join(' - ')

  return (
    <>
      <button
        type="button"
        onClick={handleResetDates}
        className={clsx(
          'ml-auto flex gap-2 rounded-md px-1 py-2 text-xs transition-all duration-200 hover:bg-neutral-100',
          hasComparisonDates ? 'z-0 mt-0 opacity-100' : '-z-1 -mt-10 opacity-0',
        )}
      >
        <span>
          {t('dates.comparisonRange')}: {formattedComparisonDates}
        </span>
        <IconX size={14} />
      </button>

      <BasePopup
        id={popupId}
        open={isOpen}
        anchor={popupAnchor}
        placement="bottom-end"
        className="z-50 mt-2 rounded-lg border border-solid border-neutral-200 bg-white p-3"
      >
        <CssTransition
          enterClassName="transition-all duration-200 translate-x-0 opacity-100"
          exitClassName="transition-all duration-200 translate-x-40 opacity-0"
        >
          <div className="p-3">
            <StaticDateRangePicker
              displayStaticWrapperAs="desktop"
              slotProps={dateRangePickerFilterSlotProps}
              rangePosition={rangePosition}
              maxDate={subDays(minimalComparationDate as Date, 1)}
              onRangePositionChange={(position) => {
                setRangePosition(position)
              }}
              onChange={(newDate) => {
                setInternalValue(newDate)
              }}
              slots={{
                calendarHeader: (props) => (
                  <DateRangePickerFilterHeader
                    onMonthSelect={handleMonthSelect}
                    value={internalValue}
                    {...props}
                  />
                ),
              }}
              value={internalValue}
              {...rest}
            />
            <div className="flex justify-between">
              <Button variant="primary-text" onClick={onBackClick}>
                {t('action.back')}
              </Button>

              <Button
                className="ml-auto"
                onClick={handleSaveDates}
                disabled={!isDiferentFromInitial}
              >
                {t('action.save')}
              </Button>
            </div>
          </div>
        </CssTransition>
      </BasePopup>
    </>
  )
}
