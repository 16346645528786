import { z } from 'zod'

export const paginationSearch = z.object({
  page: z.coerce.number().int().nonnegative().catch(0),
  pageSize: z.coerce.number().int().positive().optional(),
})

export type PaginationSearch = z.infer<typeof paginationSearch>

const sortFieldSchema = z.string().optional()
const sortDirectionSchema = z.enum(['asc', 'desc']).optional()

export const sortingSearch = z.object({
  sortField: sortFieldSchema,
  sortDirection: sortDirectionSchema,
  // These are used for calculated columns sorting (which is made on the client side)
  altSortField: sortFieldSchema,
  altSortDirection: sortDirectionSchema,
})

export type SortingSearch = z.infer<typeof sortingSearch>

export const keyDateSearch = z.object({
  keyDate: z.string().date().optional(),
})

export type KeyDateSearch = z.infer<typeof keyDateSearch>

export const columnGroupSearch = z.object({
  columnGroup: z
    .object({
      label: z.string(),
      name: z.string(),
    })
    .optional(),
})

export type ColumnGroupSearch = z.infer<typeof columnGroupSearch>

export const listSearch = sortingSearch.and(paginationSearch)

export type ListSearch = z.infer<typeof listSearch>

export const listWithKeyDateSearch = listSearch.and(keyDateSearch)

export type ListWithKeyDateSearch = z.infer<typeof listWithKeyDateSearch>

export const groupedListSearch = listWithKeyDateSearch.and(columnGroupSearch)

export type GroupedListSearch = z.infer<typeof groupedListSearch>

export const inventoryOrderSearch = z
  .object({
    orderDetails: z.record(z.string(), z.any()),
    keyColumnNames: z.array(z.string()),
    keyVisibleColumnLabelsMap: z.record(z.string(), z.string()),
  })
  .and(listWithKeyDateSearch)

export type InventoryOrderSearch = z.infer<typeof inventoryOrderSearch>
