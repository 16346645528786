import type { Filter, FilterEmpty } from '~/providers/FiltersProvider'
import type { BasicOption, BasicOption2, ModuleDataCommonParams } from './shared'

export type ApiResponse<T = unknown, D = unknown> = {
  error: ApiResponseError<D>
  message: string
  data: T
}

export type ApiResponseError<D = unknown> = {
  code: string
  message: string
  details: D
}

export type WithTotal = {
  total: number
}

export type WithColumns = {
  columns: ApiColDef[]
}

export type WithColumnLabels = {
  column_labels?: Record<string, string>
}

export type WithDateRange = {
  start_date: string // 'YYYY-MM-DD'
  end_date: string // 'YYYY-MM-DD'
}

export type ApiColDefFormat =
  | 'STRING'
  | 'CURRENCY'
  | 'FLOAT64'
  | 'INT64'
  | 'DATE'
  | 'DATETIME'
  | 'WEEK_DAYS'
  | 'PERCENTAGE'
  | 'NUMERIC'

export type ApiColumnBase = {
  type: 'KEY' | 'AGGR' | 'TIME'
}

type ApiColumnCalculatedBase = {
  type: 'CALCULATED'
  /**
   * If `type` is `CALCULATED`, this field contains the serialized function of the formula to calculate the value.
   */
  formula: string
}

export type ApiColDef = Partial<WithDateRange> &
  ({
    data_type:
      | 'INT64'
      | 'FLOAT64'
      | 'BOOL'
      | 'DATE'
      | 'STRING'
      | 'TIMESTAMP'
      | 'PERCENTAGE'
      | 'NUMERIC'
    label: string
    name: string
    format: ApiColDefFormat
    visible: boolean
    group_code: string | null
    group_label: string | null
    group_order: number | null
    // for forecast columns
    is_forecast?: boolean | null

    /**
     * If true, the column should be used as the default value for the input value field (e.g. `input_quantity`)
     */
    input_value?: boolean

    order: number
    default_client_aggregation?: 'sum' | 'avg' | 'min' | 'max' | 'size'
    docs_page?: {
      content_id: string
      page_url: string
    } | null
  } & (ApiColumnBase | ApiColumnCalculatedBase))

export type ApiColumnCalculated = ApiColDef & ApiColumnCalculatedBase

export type ApiColumnWithValue = ApiColDef & {
  value: unknown
}

export type ConfigModelSupplyChainParamsParam = {
  name: string
  unit: ConfigModelSupplyChainParamsParamUnit
  label: string
}
export type ConfigModelSupplyChainParams = {
  params: ConfigModelSupplyChainParamsParam[]
}

export interface ConfigModelSupplyChainParamsCriteria extends WithColumns, WithTotal {
  items: ConfigModelSupplyChainParamsCriteriaItem[]
}

export type StockStatus =
  | 'OUT OF STOCK (P0)'
  | 'CRITICAL STOCK (P1)'
  | 'LOW STOCK (P2)'
  | 'NORMAL STOCK (P3)'
  | 'INSUFFICIENT DATA'
  | 'NON-MOVING STOCK'
  | 'NO STOCK AND NO SALES'
  | 'UNAVAILABLE STATUS'

export type ConfigModelSupplyChainParamsCriteriaItem = {
  id: string
  key_division: string
  key_division_label: string
  key_cedi: string
  key_cedi_label: string
  key_supplier: string
  key_warehouse: string
  key_warehouse_label: string
  product_category: string
  product_sub_category: string
  value: number | string[] | null
}

export interface ForecastableProduct extends BaseProduct, Partial<WithForecastProductGroup> {
  key_division: string
  key_operational_center: string
  product_code: string
  key_warehouse: string
  product_name: string
  product_category: string
  distribution_warehouse_name: string
  distribution_warehouse_code: string
  warehouse_code: string
  warehouse_name: string
  sales_unit_price: number
  last_12_months: number
  year_to_date: number
  isModified?: boolean
  forecast_product_group?: string
  promo_applied?: boolean
}

export interface ProductPerformace {
  forecast_base_line: number
  discount: number
  net_price: number
  discount_percentage: number
  incremental_volume_cant: number
  total_forecast: number
  incremental_volume: number
  baseline_revenue: number
  incremental_revenue: number
  incremental_cost: number
  incremental_profit: number
  investment: number
  roi: number
  total_sales: number
  commercial_cost: number
  gross_sales: number
  total_costs: number
  total_gross_profit: number
  time_dimmension: Date
  value: number
  original_value: number
}

export interface ProductWithForecast extends ForecastableProduct {
  values: ProductForecastValue[]
}

export interface ProductForecastValue {
  time_dimmension: string
  value: number
  is_forecasted: boolean
  original_value?: number
  modified_value?: number
  userModifiedValue?: number
  isModified?: boolean
}

export type ConfigModelSupplyChainParamsCriteriaItemUpdate = Partial<
  Omit<
    ConfigModelSupplyChainParamsCriteriaItem,
    'key_division_label' | 'key_cedi_label' | 'key_warehouse_label' | 'value'
  >
>

export type ConfigModelSupplyChainParamsParamUnit =
  | 'DAY'
  | 'PERCENTAGE'
  | 'UNIT'
  | 'WEEK_DAYS'
  | 'BOOLEAN'
  | 'PREFERENCE'

export type ConfigModelSupplyChainParamsUpdate = {
  criteria_params: (ConfigModelSupplyChainParamsCriteriaItemUpdate & {
    params: {
      name: string
      value: string | string[] | number | null
      unit: ConfigModelSupplyChainParamsParamUnit
    }[]
  })[]
}

export type ConfigEnvironmentVariable = {
  id: string
  tenant_id: string
  name: string
  value: string
  is_secret: boolean
  user_updated: string
  last_updated: string
}

export type ConfigEnvironmentVariableCreate = Pick<
  ConfigEnvironmentVariable,
  'name' | 'value' | 'is_secret' | 'tenant_id'
>
export type ConfigEnvironmentVariableUpdate = Pick<
  ConfigEnvironmentVariable,
  'id' | 'name' | 'value' | 'is_secret'
>

export type FilterSet = {
  id: number
  name: string
  source: string
  user_id?: number
  users?: User[]
  datamart: string
  filters: FilterEmpty[]
} & WithAppName

export interface WithTimeAggregation {
  time_aggregation?: string
}

export interface WithQueryIds {
  promotionId?: string
  campaignId?: string
  scenarioId?: string
}

export interface WithForecastUnit {
  forecast_unit: string
}

export interface WithForecastProductGroup {
  forecast_product_group: string
}

export interface WithAppName {
  app_name: string
}

export interface WithUnit {
  unit?: string
}

export interface FetchProductListParams extends ModuleDataCommonParams, WithKeyDate {}

export interface FetchProductListWithForecastParams
  extends FetchProductListParams,
    WithTimeAggregation,
    WithUnit,
    WithQueryIds {
  strict_mode?: boolean
}

export interface FetchProductListExcelFileParams
  extends FetchProductListParams,
    WithColumnLabels,
    WithUnit,
    WithTimeAggregation {}

export type Entity = {
  id: string
}

export interface BaseProduct extends Entity {
  key_product: string
  key_supplier?: string
}

export type WithInputQuantity = {
  input_quantity: number | null | undefined
  input_value: number | null | undefined // used as fallback value for when input_quantity field value is -1
}

export type WithRequestedQuantity = {
  quantity_requested_with_celes: number
}

export type WithRequestedQuantityWithCeles = {
  quantity_requested_by_warehouse: number
}

export type WithKeyDate = {
  /**
   * Used to specify the day on which suggestions are generated.
   * Date string in 'YYYY-MM-DD' format.
   */
  key_date?: string
}

export interface BaseProductWithInputQuantity extends BaseProduct, WithInputQuantity {}

export interface BaseProductWithInputQuantityAndRequestedQuantity
  extends BaseProductWithInputQuantity,
    WithRequestedQuantity {}

export type FetchProductSearchParams = WithKeyDate & {
  key_supplier?: string
} & (
    | {
        key_distribution_warehouse: string
      }
    | {
        key_warehouse: string
      }
  )

export interface ProductDetails extends BaseProductWithInputQuantity, Record<string, unknown> {}

export interface ProductDetailsResponse extends WithColumns {
  item: ProductDetails
}

export interface ProcurementProductListItem extends BaseProductWithInputQuantity {
  key_distribution_warehouse: string
  key_division: string
  key_operational_center: string
  key_product: string
  key_supplier: string
  product_code: string
  product_name: string
  supplier_code: string
  supplier_name: string
  operational_center_code: string
  operational_center_name: string
  distribution_warehouse_code: string
  distribution_warehouse_name: string
  product_category: string
  product_status: string
  product_quadrant: string
  stock_classification_dw: string
  procurement_unit_price: number
  procurement_unit: string
  suggested_procurement: number
  suggested_procurement_adjusted: number
  current_stock_procurement: number
  current_stock_cedi: number
  current_stock_stores: number
  out_of_stock_warehouses: number
  daily_consumption_procurement: number
  sales_unit: string
  commited_stock: number
  uncommited_stock: number
  ordered_stock_dw: number
  ordered_stock: number
  in_transit_stock: number
  estimated_lost_sales: number
  max_projected_inventory_days: number
  estimated_procurement_cost: number
  min_inventory: number
  reorder_level: number
  max_inventory: number
  remaining_days_of_inventory_warehouses: number
  remaining_days_of_inventory_dw: number
  quantity_requested_with_celes: number
  id: string
  procurement_cost: number
  procurement_unit_weight: number | null
  surplus_cost: number | null
  shelf_inventory: number
  sales_velocity: number
  vida_util: number
}

export interface ReplenishmentProductListItem extends BaseProductWithInputQuantity {
  key_warehouse: string
  key_distribution_warehouse: string
  product_quadrant: string
  distribution_warehouse_code: string
  distribution_warehouse_name: string
  daily_consumption_distribution: number
  estimated_lost_sales: number
  min_projected_inventory_days: number
  max_projected_inventory_days: number
  distinct_products: number
  products_with_suggestions: number
  quantity_requested_with_celes: number
  min_inventory: number
  ordered_stock: number
  in_transit_stock: number
  daily_consumption_replenishment: number
  current_stock_replenishment: number
  suggested_replenishment: number | null
}

export interface BaseList<T = never> extends WithColumns, WithTotal {
  items: T[]
}

export interface ProductListWithForecastResponse extends BaseList<ProductWithForecast> {}

export interface ProductListWithPerformaceReponse extends BaseList<ProductPerformace> {}

export type ProcurementProductList = BaseList<ProcurementProductListItem>

export type ReplenishmentProductList = BaseList<ReplenishmentProductListItem>

export type ProductListGroupColumns<T extends BasicOption2 = BasicOption2> = {
  groups: T[]
}

export type ReplenishmentProductListGroupColumnsItem = BasicOption2
export type ProcurementProductListGroupColumnsItem = BasicOption2

export type ProcurementProductListGroupColumns =
  ProductListGroupColumns<ProcurementProductListGroupColumnsItem>
export type ReplenishmentProductListGroupColumns =
  ProductListGroupColumns<ReplenishmentProductListGroupColumnsItem>

export type OrderCreateData = {
  [key: string]: unknown
  order_notes?: string
  order_date?: string
  items: BaseProduct[]
}

export type Product = ProcurementProductListItem

export interface ReplenishmentSuggestionListItem extends BaseProductWithInputQuantity {
  key_division: string
  key_operational_center: string
  key_product: string
  key_warehouse: string
  product_code: string
  product_name: string
  product_quadrant: string
  suggested_replenishment: number
  distribution_unit: string
  daily_consumption_distribution: number
  in_transit_stock_distribution: number
  input_notes: string | null
}

export type ReplenishmentSuggestionsList = BaseList<ReplenishmentSuggestionListItem>

export type ReplenishmentSuggestionsOrderCreate = {
  items: ReplenishmentSuggestionListItem[]
}

export interface ReplenishmentSuggestionsItemCreate extends WithInputQuantity {
  key_operational_center: string
  key_warehouse: string
  key_product: string
}

export type InventoryWarehousesItemResponse = {
  key_warehouse: string
  code: string
  name: string
  address: string
  contact: string
}

export type InventoryWarehousesResponse = {
  warehouses: InventoryWarehousesItemResponse[]
}

export type TenantsListResponse = {
  tenants: Tenant[]
}

export type Tenant = {
  global_id: string
  company: string
  language: string
  code: string
  is_in_europe?: boolean
  currency?: string // TODO: not implemented yet by the API
}

export type TenantCreate = {
  company: string
  is_in_europe: boolean
  language: string
}

export type TenantCreateResponse = {
  id: string
  provider_tenant_id: string
}

export type TenantModule = {
  name: string
  active: boolean
  submodules: Omit<TenantModule, 'submodules'>[]
}

export type TenantsModulesResponse = {
  modules: TenantModule[]
}

//TODO: Validate if this type will be equal to Role
export type Permission = {
  id: number
  name: string
  code: string
  active: boolean
}

export type Role = {
  id: number
  name: string
  priority: number
  landing_url: string
  active: boolean
  permissions?: Permission[]
}

export type TenantUserRolesResponse = {
  user: User
}

export interface User extends WithGlobalId {
  id: number
  global_id: string
  email: string
  name: string
  picture: string | null
  roles: Role[]
  internal_code: string | null
  active: boolean
  inviter_email?: string | null
}

export type UserWarehousestResponse = {
  warehouses: string[]
}

export type InventoryMetric = {
  name: string
  label: string
  value: number
  unit: string
  target: string
  optimization_direction: string
  active?: boolean
  reference_percentage: number
  comparison_value: number | null
}

export type SellThroughRate = {
  date: string
  input: number
  output: number
  rate: number
}

export enum FilterSetSource {
  USER = 'user',
  APP = 'app',
}

export type FilersSet = {
  datamart: string
  name: string
  id: number
  source: FilterSetSource
}

/// TODO: this for now because some operator names have changed, but only in the filters; they haven’t changed on the backend side for get-items. Once the backend is updated, this will need to be updated here as well.
export type NewFilter = {
  field?: string
  name: string
  column_id: number
  operator:
    | 'is'
    | 'is not'
    | 'not in'
    | 'in'
    | 'greater than'
    | 'greaterOrEqual'
    | 'less than'
    | 'lessOrEqual'
    | 'between'
    | 'is empty'
    | 'is not empty'
    | unknown
  value: string[]
}

export type FiltersSetCreateResponse = {
  filter_set: FilersSet
}

export type FilterOptionDatatype = 'STRING' | 'WEEK_DAYS' | 'NUMERIC'

export type FilterOption = {
  data_type: FilterOptionDatatype
  datamart: string
  field: string
  format: string
  id: number
  label: string
}

export type FetchGenericOrderRestrictionsParams = {
  key_operational_center: string
  key_supplier: string
}

export type FilterFieldOption = {
  // TODO: this would probably change
  value: string
}

export type InventoryGenericOrderRestrictionsResponse = {
  restrictions: BasicOption<null>[]
}

export type ProductsCollision = {
  category_name: string
} & Pick<Product, 'product_name'>

// TODO: it is necessary to delete previously created campaigns to remove statuses that are not “approved,” “rejected,” or “created.”
export enum CampaignStatus {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CREATED = 'Created',
  ARCHIVED = 'Archived',
  DRAFT = 'Draft',
  FINISHED = 'Finished',
  DONE = 'Done',
  ACTIVE = 'Active',
}

export interface Campaign extends WithGlobalId {
  id: number
  name: string
  target: string
  comments: string
  status: CampaignStatus
}

// Delete this type when the API is ready
export interface CampaignDetails {
  name: string
  id: string
  status: Campaign['status']
  promotions: PromotionSmall[]
}

export interface WithTimestamps {
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
}

export interface WithGlobalId {
  global_id: string
}

export interface CampaignDetailsResponse extends CampaignDetails, WithTimestamps {}

export interface CampaignsResponse extends BaseList<Campaign> {}

export interface WithForecastTimeAggregation {
  forecast_time_aggregation: string
}

export interface Promotion extends WithGlobalId, WithDateRange {
  name: string
}

export interface PromotionCollisionResponse extends WithDateRange {
  campaign_id: string
  discount: number
  global_id: string
  name: string
  status: string
  products: ProductsCollision[]
}

export interface Estimation {
  total_volume_uplit: number
  total_profit: number
  total_investment: number
  total_incremental_revenue: number
  roi: number
}

export type ScenarioStatus = 'Approved' | 'Rejected' | 'Created'

export interface Scenario
  extends WithDateRange,
    WithTimestamps,
    WithGlobalId,
    WithForecastTimeAggregation {
  id: string
  name: string
  comments: string
  status: ScenarioStatus
}

export interface ScenariosResponse extends BaseList<Scenario> {}

export interface PromotionSmall extends WithGlobalId {
  name: string
  comment: string
  id: string
  createdAt: string
}

export interface GroupColumn extends BasicOption2 {
  group: string[]
  filters: unknown[]
  app_exclusive: boolean
  apps: string[]
}

export interface GroupColumnsResponse {
  groups: GroupColumn[]
}

export interface DatasetColumn {
  id: number
  label: string
  format: string
  datamart: string
  field: string
  data_type: string
}

export type ConfigColumnsResponse = {
  items: DatasetColumn[]
  num_pages: number
  total: number
}

export type FilterSetDataPermission = {
  column_id: number
  field?: string
} & Pick<Filter, 'operator' | 'value'>

export type UserDataPermissionCreateParams = {
  name: string
  source: string
  user_id?: number
  datamart: string
  filters: FilterSetDataPermission[]
} & Partial<WithAppName>

export type ProcurementCalendarDays = {
  orders_days: string[]
}

export type ProcurementCalendarItemDetails = {
  id: string
  [key: string]: string | number
}

export interface ProcurementCalendarItem extends BaseList<ProcurementCalendarItemDetails> {
  order_day: string // 'YYYY-MM-DD'
  __parsedOrderDay?: Date | null
}

export type ProcurementCalendarItemsResponse = ProcurementCalendarItem[]

export type KnowledgeBasePageParams = {
  content_id: string
}

type NotionAnnotationResponse = {
  bold: boolean
  italic: boolean
  strikethrough: boolean
  underline: boolean
  code: boolean
  // i removed the color property because it is not needed
}

export type NotionRichTextResponse = {
  type: 'text'
  text: {
    content: string
    link: { url: string } | null
  }
  annotations: NotionAnnotationResponse
  plain_text: string
  href: string | null
}

export type KnowledgeBasePageResponse = {
  page_url: string
  summary: NotionRichTextResponse[]
}

export type KnowledgeBasePagesResponse = {
  [content_id: string]: string
}

export type ColumnGroups = {
  id: number
  name: string
  label: string
  datamart: string
  include_quantity: boolean
  app: string
  operation_option: string
  group_columns: string[]
}

export type Aggregation = {
  id: number
  sentence: string
  format?: ApiColDefFormat
  section_code: string
  section_label: string
  section_order: number
  input_value: boolean
  formula: string
  type: string
} & Pick<ApiColDef, 'data_type' | 'label' | 'name' | 'order'>

export type AppFilter = {
  id: number
  name: string
  datamart: string
  columns: DatasetColumn[]
}

export type InventoryBalancing = {
  id: number
}

export type EventDefinition = {
  [key: string]: string[]
}

export type DynamicQuery = {
  id: number
  slug: string
  description: string
  source: DynamicQueryDataSource
}

export type DynamicQueryDataSource = {
  id: number
  name: string
  source_type: string
}

export type FieldType =
  | 'SIMPLE'
  | 'EXPRESSION'
  | 'EXTRACT'
  | 'FUNCTION'
  | 'AGGREGATE'
  | 'LITERAL'
  | 'CASE'
  | 'SQL_RAW'

export type DataType =
  | 'INT64'
  | 'FLOAT64'
  | 'BOOL'
  | 'DATE'
  | 'STRING'
  | 'TIMESTAMP'
  | 'PERCENTAGE'
  | 'NUMERIC'

export type DynamicQueryField = {
  id: number
  name: string
  label: string
  data_type: string
  field_type: FieldType
  source: DynamicQueryDataSource
  format: string
  statement: string
  visible?: boolean
  values?: string[]
}

export type DynamicQueryJoin = {
  id: number
  source: DynamicQueryDataSource
  join_type: string
  alias: string
  field_a: DynamicQueryField
  field_b: DynamicQueryField
}

export type DynamicQueryFieldCreate = {
  id?: number
  data_source_id?: number
  column_id?: number
  name?: string
  label: string
  field_type: FieldType
  statement?: string
  data_type: DataType
  format?: string
  purpose?: string
  visible?: boolean
}

export type DynamicQueryJoinCreate = {
  join_type: string
  alias: string
  source_id: number
  column_a_id: number
  column_b_id: number
}

export type DynamicQueryFilter = {
  id: number
  comparator: DynamicQueryComparator
  values: string[]
  field_a: DynamicQueryField
  field_b: DynamicQueryField
}

export type DynamicQueryComparator =
  | 'EQ'
  | 'NOT_EQ'
  | 'GT'
  | 'GTE'
  | 'LT'
  | 'LTE'
  | 'IN'
  | 'NOT_IN'
  | 'IS_EMPTY'
  | 'IS_NOT_EMPTY'

export type DynamicQueryFilterCreate = {
  id?: number
  data_source_a_id: number
  column_a_id: number
  comparator: DynamicQueryComparator
  values_data_type: DataType
  values: string[]
}

export type DynamicQueryColumn = Pick<
  ApiColDef,
  'name' | 'label' | 'type' | 'format' | 'data_type' | 'visible'
>

export type DynamicQueryItem = {
  [key: string]: number | string | boolean | null
}

export interface BaseAdministrationProduct {
  name: string
  description: string
  id: number
  columns: ProductColumns[]
}

export type ProductColumns = {
  description: string
  value: string
  aggregation: string
  column_config_id?: number
  column_id?: number
  targetset_id?: number
}

export interface SyntheticProduct extends BaseAdministrationProduct {
  filter_set_id: number
}

export interface SubstituteProduct extends BaseAdministrationProduct {
  filterset_id_substituted: number
  filterset_id_target: number
}

// TODO: refactor this type later
export type ProductFilter = {
  id: number
  datamart: string
  column: DatasetColumn
  operator: Filter['operator']
  value: string[]
}

export type FilterSetResponse = {
  filter_set: {
    id: number
    name: string
    datamart: string
    filters: ProductFilter[]
  }
}
