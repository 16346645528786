import {
  GRID_ACTIONS_COLUMN_TYPE,
  GRID_AGGREGATION_ROOT_FOOTER_ROW_ID,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@mui/x-data-grid-premium'
import type {
  GridActionsColDef,
  GridAggregationModel,
  GridAlignment,
  GridCallbackDetails,
  GridColDef,
  GridColumnGroup,
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowId,
  GridRowSelectionModel,
  GridSortItem,
  GridSortModel,
  GridValidRowModel,
  GridValueFormatter,
} from '@mui/x-data-grid-premium'
import { GRID_ID_AUTOGENERATED } from '@mui/x-data-grid/internals'
import { useSearch } from '@tanstack/react-router'
import { groupBy, mapValues } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocalStorage, usePrevious } from 'react-use'
import type { CalculatedColumnParsedSerializedFunction } from '~/lib/core/calculatedColumns'
import {
  checkIfColumnIsCalculated,
  evaluateParsedFunction,
  parseSerializedFunction,
} from '~/lib/core/calculatedColumns'
import i18n from '~/lib/i18n'
import type { ColumnGroupSearch, PaginationSearch, SortingSearch } from '~/lib/searchSchemas'
import { router } from '~/lib/tanstackRouter'
import type {
  ApiColDef,
  ApiColumnBase,
  ApiColumnCalculated,
  ApiColumnWithValue,
} from '~/types/apiContracts'
import type { BasicOption2 } from '~/types/shared'
import { formatShortDate } from '~/utils/date'
import { formatCurrency, formatDecimal, formatInteger, formatPercentage } from '~/utils/number'
import { formatWeekDayCodesFromString } from '../../lib/core/weekDays'
import { ColumnHeaderWithKnowledgeBaseIconButton } from './ColumnHeaderWithKnowledgeBaseIconButton'
import {
  filterKeyVisibleApiColumns,
  filterNumericNotKeyApiColumns,
  filterVisibleApiColumns,
  getColDefAlign,
  getColDefType,
  getColHeaderName,
  isNumericNotKeyApiColumn,
  sortColumns,
} from './helpers'
import type { OptionsDataTable, OptionsDataTableWithCustomFunctions } from './types'

/**
 * Returns an object mapping column fields to their corresponding header names.
 *
 * @param colDefs - An array of column definitions.
 * @returns An object with column fields as keys and header names as values.
 */
export function getColumnHeaderNames(colDefs: GridColDef[]) {
  return colDefs.reduce<Record<string, string>>((obj, colDef) => {
    if (!colDef.field || !colDef.headerName) return obj
    const nameField = colDef.field

    obj[nameField] = colDef.headerName
    return obj
  }, {})
}

export const valueFormatters: Partial<
  Record<
    ApiColDef['format'],
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (value: any) => string
  >
> = {
  CURRENCY: formatCurrency,
  INT64: formatInteger,
  FLOAT64: formatDecimal,
  DATE: formatShortDate,
  WEEK_DAYS: formatWeekDayCodesFromString,
  PERCENTAGE: formatPercentage,
}

export function isFooterRow(row: GridValidRowModel): boolean {
  const footerRowSymbol = Object.getOwnPropertySymbols(row).find(
    (symbol) => symbol.description === GRID_ID_AUTOGENERATED.description,
  )

  return !!footerRowSymbol && row[footerRowSymbol] === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID
}

function getValueFormatter(apiCol: ApiColDef): GridValueFormatter | undefined {
  const valueFormatter = valueFormatters[apiCol.format]

  if (!valueFormatter) {
    return undefined
  }

  // this wrapper prevents formatting undefined values in the footer row
  const wrappedFormatter: GridValueFormatter = (value, row) => {
    if (isFooterRow(row) && value == null) {
      return null
    }

    return valueFormatter(value)
  }

  return wrappedFormatter
}

/**
 * This function returns a definition of the grouped columns, using the columns returned by the server and the criteria imposed on each column group.
 */
export function getColumnGroupModel(
  columns: ApiColDef[],
  groupDefinitions: {
    headerName: string
    groupId: string
    headerClassName?: string
    headerAlign?: GridAlignment
    children?: { field: string }[]
    criteria: (column: ApiColDef) => boolean
    fieldExtractor: (column: ApiColDef) => { field: string }
  }[],
): GridColumnGroup[] {
  return groupDefinitions.map((groupDef) => {
    const { criteria, fieldExtractor, children, ...rest } = groupDef

    if (children?.length) {
      return { ...rest, children }
    }

    const groupColumns = columns.filter(criteria)
    return {
      ...rest,
      children: groupColumns.map(fieldExtractor),
    }
  })
}

/**
 * Organizes columns by type, grouping them based on the `keys` array.
 * Columns with types not present in `keys` are grouped under 'normal'.
 */
export function getColumnsByType(
  apiColumns: ApiColDef[],
  keys: string[],
): { [key: string]: ApiColDef[] } {
  return mapValues(
    groupBy(apiColumns, (col) => (keys.includes(col.type) ? col.type : 'normal')),
    (cols, key) =>
      key === 'normal'
        ? cols
        : cols.map((col) => ({
            ...col,
            is_forecast: col.is_forecast ?? true,
          })),
  )
}

/**
 * Processes grouped columns by filtering them using specified keys and applying their corresponding options.
 * Normal groupings are processed using the provided global options.
 */
function processGroupedColumns(
  processedColumns: ApiColDef[],
  keysGroup: Partial<Record<ApiColumnBase['type'], OptionsDataTableWithCustomFunctions>>,
  globalOptions: OptionsDataTable,
): GridColDef[] {
  const columnsByType = getColumnsByType(processedColumns, Object.keys(keysGroup))

  let processedColumnsArray: GridColDef[] = columnsByType.normal
    ? mapApiColDef(columnsByType.normal, globalOptions)
    : []

  Object.entries(keysGroup).forEach(([key, options]) => {
    if (columnsByType[key as ApiColumnBase['type']]) {
      processedColumnsArray = [
        ...processedColumnsArray,
        ...mapApiColDef(columnsByType[key as ApiColumnBase['type']] ?? [], options),
      ]
    }
  })

  return processedColumnsArray
}

export function processApiColumns(
  apiColumns: ApiColDef[] = [],
  options: OptionsDataTable = { applySort: true },
): GridColDef[] {
  let processedColumns = options.applySort ? sortColumns(apiColumns) : apiColumns

  processedColumns = filterVisibleApiColumns(processedColumns)

  if (options.specificKey) {
    return processGroupedColumns(processedColumns, options.specificKey, options)
  }

  return mapApiColDef(processedColumns, options)
}

export function addSpecialColumnsDef(columns: GridColDef[]) {
  const specialColumns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      hideable: false,
      headerName: i18n.t('common:action.collapse'),
    },
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      hideable: false,
      headerName: i18n.t('common:action.select'),
    },
  ]

  return [...specialColumns, ...columns]
}

export function mapApiColDef(
  apiColumns: ApiColDef[],
  options: OptionsDataTableWithCustomFunctions,
): GridColDef[] {
  const { customHeaderRender, customRenderCell } = options

  return apiColumns.map((apiCol) => {
    const specificFieldProps = options.specificField?.[apiCol.name] ?? {}
    const headerName = getColHeaderName(apiCol)

    let colDef: GridColDef = {
      field: apiCol.name,
      headerName: headerName,
      type: getColDefType(apiCol.data_type),
      align: getColDefAlign(apiCol),
      headerAlign: getColDefAlign(apiCol),
      valueFormatter: getValueFormatter(apiCol),
      aggregable: isNumericNotKeyApiColumn(apiCol),
      ...options?.defaultColDefProps,
      ...specificFieldProps,
    }

    if (customHeaderRender) {
      colDef.renderHeader = () => customHeaderRender(apiCol)
    }
    if (customRenderCell) {
      colDef.renderCell = (params) => customRenderCell(params, apiCol)
    }

    if (apiCol.docs_page) {
      const pageContentId = apiCol.docs_page.content_id
      const pageUrl = apiCol.docs_page.page_url
      colDef.renderHeader = (params) =>
        ColumnHeaderWithKnowledgeBaseIconButton({
          ...params,
          headerName,
          pageContentId,
          pageUrl: pageUrl,
        })
    }

    if (checkIfColumnIsCalculated(apiCol)) {
      colDef = handleCalculatedColumn(colDef, apiCol)
    }

    return colDef
  })
}

function handleCalculatedColumn(colDef: GridColDef, apiCol: ApiColumnCalculated): GridColDef {
  // @ts-expect-error just a flag for debugging
  colDef.__isCalculatedColumn = true

  // Wrapper for the valueFormatter to prevent formatting error messages as if they were numbers
  colDef.valueFormatter = (value) => {
    if (typeof value === 'string') {
      return value
    }

    return valueFormatters[apiCol.format]?.(value)
  }

  let parsedSerializedFunction: CalculatedColumnParsedSerializedFunction | undefined

  try {
    parsedSerializedFunction = parseSerializedFunction(apiCol.formula)
  } catch (error) {
    colDef.valueGetter = () => i18n.t('common:invalidFormula')
    return colDef
  }

  colDef.valueGetter = (_, row) => {
    try {
      const result = evaluateParsedFunction(parsedSerializedFunction, row)
      return result
    } catch (error) {
      console.group(`Error calculating '${apiCol.name}' column formula`)
      console.error(error) // intentional console log
      console.groupEnd()

      return i18n.t('common:formulaError')
    }
  }

  return colDef
}

/**
 * The wrapped `getActions` function doesn't render any action if it is the agggregation column
 */
export function wrapColDefGetActions(
  getActions: GridActionsColDef['getActions'],
): GridActionsColDef['getActions'] {
  return (params) => {
    if (params.id === GRID_AGGREGATION_ROOT_FOOTER_ROW_ID) {
      return []
    }

    return getActions(params)
  }
}

export const GRID_ACTIONS_FIELD = 'actions'

/**
 * This function wraps the action column to prevent it from being hidden and disables the export.
 */
export function wrapActionsColDef(
  colDef: Omit<GridActionsColDef, 'field' | 'type' | 'headerName' | 'hiddeable' | 'disableExport'>,
): GridActionsColDef {
  const { getActions, ...restColDef } = colDef

  return {
    ...restColDef,
    field: GRID_ACTIONS_FIELD,
    type: GRID_ACTIONS_COLUMN_TYPE,
    headerName: i18n.t('common:actions'),
    getActions: wrapColDefGetActions(getActions),
    hideable: false,
    disableExport: true,
    minWidth: colDef?.minWidth ?? 95,
  }
}

/**
 * Filter only the columns with 'KEY' type and visible true, then return the key columns along with their labels.
 */
export function getKeyVisibleApiColumnNamesWithLabels(
  colDefs: ApiColDef[] = [],
): Record<string, string> {
  return filterKeyVisibleApiColumns(colDefs).reduce(
    (acc, currentValue) => {
      acc[currentValue.name] = currentValue.label
      return acc
    },
    {} as Record<string, string>,
  )
}

/**
 * Get the DataGrid AggregationModel object from the API columns.
 * It creates the model only from the numeric columns.
 */
function getAggregationModelFromApiColumns(colDefs: ApiColDef[] = []): GridAggregationModel {
  const numericApiColumns = filterNumericNotKeyApiColumns(colDefs)

  return numericApiColumns.reduce<GridAggregationModel>(
    (obj, { name, default_client_aggregation }) => {
      obj[name] = default_client_aggregation || 'sum'
      return obj
    },
    {},
  )
}

const paginationModelInitialPage = 0

export function usePaginationModel(initialPageSize = 25) {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: paginationModelInitialPage,
    pageSize: initialPageSize,
  })

  const resetPage = useCallback(() => {
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page: paginationModelInitialPage,
    }))
  }, [])

  return { paginationModel, setPaginationModel, resetPage }
}

const initialSortModel: GridSortModel = []

export function useSortModelPersisted(localStorageKey: string) {
  const [sortModel, setSortModel] = useLocalStorage<GridSortModel>(
    localStorageKey,
    initialSortModel,
  )

  const handleSortModelChange = useCallback(
    (newModel: GridSortModel) => {
      setSortModel(newModel)
    },
    [setSortModel],
  )

  const resetSortModel = useCallback(() => {
    setSortModel(initialSortModel)
  }, [setSortModel])

  return {
    sortModel,
    handleSortModelChange,
    resetSortModel,
  }
}

/**
 * This function extends the `useSortModelPersisted` hook by adding the ability to sort
 * by calculated columns, which cannot be used as sort field for the backend. So it is
 * handled separately.
 *
 * The `sortModel` is used to sort the data in the backend.
 * The `sortItemCalculated` is used to sort the data in the frontend.
 *
 * We preserve the server sortModel when there is a calculated column sortItem
 * to keep the current page items. Otherwise, the page items could change.
 */
export function useSortModelWithCalculateColumns(localStorageKey: string) {
  const { sortModel, handleSortModelChange, resetSortModel } =
    useSortModelPersisted(localStorageKey)
  const [sortItemCalculated, setSortItemCalculated] = useLocalStorage<GridSortItem | null>(
    `${localStorageKey}-calculatedSortItem`,
  )

  const handleSortModelChangeExtended = useCallback(
    (newModel: GridSortModel, details: GridCallbackDetails) => {
      const [firstSortItem] = newModel

      if (!firstSortItem) {
        if (sortItemCalculated) {
          setSortItemCalculated(null)
          return
        }

        resetSortModel()
        return
      }

      const column = details.api.getColumn(firstSortItem.field)

      const isCalculatedColumn = '__isCalculatedColumn' in column && !!column.__isCalculatedColumn

      if (isCalculatedColumn) {
        setSortItemCalculated(firstSortItem)
        return
      }

      handleSortModelChange(newModel)
    },
    [resetSortModel, handleSortModelChange, sortItemCalculated, setSortItemCalculated],
  )

  const resolvedSortModel = useMemo(() => {
    return sortItemCalculated ? [sortItemCalculated] : sortModel
  }, [sortItemCalculated, sortModel])

  return {
    sortModel,
    /**
     * Contains the sort model for calculated columns or the sort model for the rest of the columns.
     */
    resolvedSortModel,
    sortItemCalculated,
    handleSortModelChange: handleSortModelChangeExtended,
    resetSortModel,
  }
}

export function useAggregationModelFromColumns(apiColumns?: ApiColDef[]) {
  const [aggregationModel, setAggregationModel] = useState<GridAggregationModel>()

  const setInitialAggregationModel = useCallback(() => {
    if (!apiColumns) {
      return
    }

    const newAggregationModel = getAggregationModelFromApiColumns(apiColumns)

    setAggregationModel(newAggregationModel)
  }, [apiColumns])

  useEffect(() => {
    if (!aggregationModel) {
      setInitialAggregationModel()
    }
  }, [aggregationModel, setInitialAggregationModel])

  return {
    aggregationModel,
    setAggregationModel,
    setInitialAggregationModel,
  }
}

export function useRowSelectionModelUtils() {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([])

  const addRowIdToSelectionModel = useCallback((rowId: GridRowId) => {
    setRowSelectionModel((prevSelection) => {
      if (prevSelection.includes(rowId)) {
        return prevSelection
      }

      return [...prevSelection, rowId]
    })
  }, [])

  const removeRowIdFromSelectionModel = useCallback((rowId: GridRowId) => {
    setRowSelectionModel((prevSelection) => {
      return prevSelection.filter((id) => id !== rowId)
    })
  }, [])

  const checkIfRowIsSelected = useCallback(
    (rowId: GridRowId) => {
      return rowSelectionModel.includes(rowId)
    },
    [rowSelectionModel],
  )

  const helpers = useMemo(() => {
    return {
      rowSelectionModel,
      setRowSelectionModel,
      addRowIdToSelectionModel,
      removeRowIdFromSelectionModel,
      checkIfRowIsSelected,
    }
  }, [
    rowSelectionModel,
    addRowIdToSelectionModel,
    removeRowIdFromSelectionModel,
    checkIfRowIsSelected,
  ])

  return helpers
}

export function useColumnVisibilityModelFrom(localStorageKey: string) {
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useLocalStorage<GridColumnVisibilityModel>(localStorageKey, {})

  return { columnVisibilityModel, setColumnVisibilityModel }
}

export const getColumnsGroupNameKey = (moduleId: string) => `${moduleId}.group-columns`

export const useColumnsGroup = (
  moduleId: string,
  isSuccessColumnsGroupQuery: boolean,
  columnsGroupData: BasicOption2[] = [],
  defaultAggregationName?: string,
) => {
  const [columnsGroup, setColumnsGroup] = useLocalStorage<BasicOption2 | null>(
    getColumnsGroupNameKey(moduleId),
    null,
  )

  /**
   * If the columnGroup is set, but it doesn't exist in the list of groups from the query
   * it means that the group is not available anymore, so we need to reset the group to the first one
   * available in the list
   */
  useEffect(() => {
    if (!columnsGroup || !isSuccessColumnsGroupQuery) return

    if (defaultAggregationName && defaultAggregationName !== columnsGroup.name) {
      const defaultAggregationExists = columnsGroupData.find(
        (group) => group.name === defaultAggregationName,
      )
      if (defaultAggregationExists) {
        setColumnsGroup(defaultAggregationExists)
        return
      }
    }

    const groupExists =
      columnsGroupData.findIndex((group) => group.name === columnsGroup.name) !== -1

    if (groupExists) return

    const [firstGroup] = columnsGroupData

    if (firstGroup) {
      setColumnsGroup(firstGroup)
    }
  }, [
    columnsGroup,
    columnsGroupData,
    isSuccessColumnsGroupQuery,
    setColumnsGroup,
    defaultAggregationName,
  ])

  const isGrouped = !!columnsGroup
  const groupFields = columnsGroup?.name ? [columnsGroup.name] : []

  // a state to control if the columnsGroup value was already initiallized for the first time
  const [hasColumnGroupBeenInitialized, setHasColumnGroupBeenInitialized] = useState(false)

  /**
   * If the columnsGroup state is not set and the query has data,
   * we set the first group from the query as the initial group
   */
  useEffect(() => {
    if (isGrouped || hasColumnGroupBeenInitialized || !isSuccessColumnsGroupQuery) return

    const [firstGroup] = columnsGroupData

    if (firstGroup) {
      setColumnsGroup(firstGroup)
      setHasColumnGroupBeenInitialized(true)
    }
  }, [
    hasColumnGroupBeenInitialized,
    columnsGroupData,
    isSuccessColumnsGroupQuery,
    isGrouped,
    setColumnsGroup,
  ])

  return { columnsGroup, setColumnsGroup, isGrouped, groupFields }
}

type UseSearchPaginationModelOptions = {
  /**
   * Defaults to 25.
   */
  initialPageSize?: number
}

export function useSearchPaginationModel(props: UseSearchPaginationModelOptions = {}) {
  const { initialPageSize = 25 } = props

  const search: PaginationSearch = useSearch({
    strict: false,
  })

  const paginationModel: GridPaginationModel = useMemo(() => {
    return {
      page: search.page || paginationModelInitialPage,
      pageSize: search.pageSize || initialPageSize,
    }
  }, [search.page, search.pageSize, initialPageSize])

  const setPaginationModel = useCallback((newPaginationModel: Partial<GridPaginationModel>) => {
    router.navigate({
      // @ts-ignore hard type
      search: (prev) => {
        return {
          ...prev,
          page: newPaginationModel.page ?? prev.page,
          pageSize: newPaginationModel.pageSize ?? prev.pageSize,
        }
      },
    })
  }, [])

  const resetPage = useCallback(() => {
    setPaginationModel({
      page: paginationModelInitialPage,
    })
  }, [setPaginationModel])

  return {
    paginationModel,
    setPaginationModel,
    resetPage,
  }
}

export function useSearchSortModelWithCalculateColumns() {
  const search: SortingSearch = useSearch({
    strict: false,
  })

  const sortModel: GridSortModel = useMemo(() => {
    const sortModel: GridSortModel = []

    if (!search.sortField) return sortModel

    sortModel.push({
      field: search.sortField,
      sort: search.sortDirection || 'asc',
    })

    return sortModel
  }, [search.sortField, search.sortDirection])

  const setSortModel = useCallback((newPaginationModel: GridSortModel) => {
    router.navigate({
      // @ts-ignore hard type
      search: (prev) => {
        if (newPaginationModel.length === 0) {
          return {
            ...prev,
            sortField: undefined,
            sortDirection: undefined,
          }
        }

        return {
          ...prev,
          sortField: newPaginationModel[0].field,
          sortDirection: newPaginationModel[0].sort || 'asc',
        }
      },
    })
  }, [])

  const sortItemCalculated: GridSortItem | null = useMemo(() => {
    if (!search.altSortField) return null

    return {
      field: search.altSortField,
      sort: search.altSortDirection || 'asc',
    }
  }, [search.altSortField, search.altSortDirection])

  const setSortItemCalculated = useCallback((newSortItem: GridSortItem | null) => {
    router.navigate({
      // @ts-ignore hard type
      search: (prev) => {
        if (!newSortItem) {
          return {
            ...prev,
            altSortField: undefined,
            altSortDirection: undefined,
          }
        }

        return {
          ...prev,
          altSortField: newSortItem?.field,
          altSortDirection: newSortItem?.sort || 'asc',
        }
      },
    })
  }, [])

  const resetSortModel = useCallback(() => {
    setSortModel(initialSortModel)
  }, [setSortModel])

  const handleSortModelChangeExtended = useCallback(
    (newModel: GridSortModel, details: GridCallbackDetails) => {
      const [firstSortItem] = newModel

      if (!firstSortItem) {
        if (sortItemCalculated) {
          setSortItemCalculated(null)
          return
        }

        resetSortModel()
        return
      }

      const column = details.api.getColumn(firstSortItem.field)

      const isCalculatedColumn = '__isCalculatedColumn' in column && !!column.__isCalculatedColumn

      if (isCalculatedColumn) {
        setSortItemCalculated(firstSortItem)
        return
      }

      setSortModel(newModel)
    },
    [resetSortModel, setSortModel, sortItemCalculated, setSortItemCalculated],
  )

  const resolvedSortModel = useMemo(() => {
    return sortItemCalculated ? [sortItemCalculated] : sortModel
  }, [sortItemCalculated, sortModel])

  return {
    sortModel,
    /**
     * Contains the sort model for calculated columns or the sort model for the rest of the columns.
     */
    resolvedSortModel,
    sortItemCalculated,
    handleSortModelChange: handleSortModelChangeExtended,
    resetSortModel,
  }
}

export const useSearchColumnGroup = (columnGroupsOptions: BasicOption2[] | undefined) => {
  const search: ColumnGroupSearch = useSearch({
    strict: false,
  })

  const columnGroup: BasicOption2 | null = useMemo(() => {
    if (!search.columnGroup) return null

    return {
      label: search.columnGroup.label,
      name: search.columnGroup.name,
    }
  }, [search.columnGroup])

  const setColumnGroup = useCallback((columnGroup: BasicOption2 | null) => {
    router.navigate({
      // @ts-ignore hard type
      search: (prev) => {
        if (!columnGroup) {
          return {
            ...prev,
            columnGroup: undefined,
          }
        }

        return {
          ...prev,
          columnGroup: {
            label: columnGroup.label,
            name: columnGroup.name,
          },
        }
      },
    })
  }, [])

  const prevColumnGroup = usePrevious(columnGroup)

  /**
   * If the columnGroup is set, but it doesn't exist in the list of groups from the query
   * it means that the group is not available anymore, so we need to reset the group to the first one
   * available in the list
   */
  useEffect(() => {
    if (!columnGroup || !columnGroupsOptions) return

    const groupExists =
      columnGroupsOptions.findIndex((group) => group.name === columnGroup.name) !== -1

    if (groupExists) return

    const [firstGroup] = columnGroupsOptions

    if (firstGroup) {
      setColumnGroup(firstGroup)
    }
  }, [columnGroup, columnGroupsOptions, setColumnGroup])

  const isGrouped = !!columnGroup

  /**
   * If the columnGroup state is not set and the query has data,
   * we set the first group from the query as the initial group
   */
  useEffect(() => {
    if (isGrouped || !columnGroupsOptions) return

    const [firstGroup] = columnGroupsOptions

    if (firstGroup) {
      setColumnGroup(firstGroup)
    }
  }, [columnGroupsOptions, isGrouped, setColumnGroup])

  const groupFields = columnGroup?.name ? [columnGroup.name] : []

  return { columnGroup, setColumnGroup, prevColumnGroup, isGrouped, groupFields }
}

export function mapColumnsWithValues(
  item: GridValidRowModel,
  columns: ApiColDef[],
): ApiColumnWithValue[] {
  return columns.map((col) => {
    return {
      ...col,
      value: item[col.name],
    }
  })
}
