import { IconX } from '@tabler/icons-react'
import clsx from 'clsx/lite'
import { useTranslation } from 'react-i18next'
import { Loader } from '~/components/UI/Loader'

type FiltersSetChipProps = {
  label: string
  onClick?: () => void
  onDelete?: () => void
  disabled?: boolean
  loading?: boolean
  isUserSource?: boolean
}

export const FiltersSetChip = (props: FiltersSetChipProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={clsx(
        'inline-flex h-7.5 w-28 items-center gap-1.5 rounded-[10px] border border-night-200 pe-2.5 ps-2',
        !!props.onClick && !props.disabled && 'cursor-pointer hover:bg-night-50',
        props.disabled ? 'cursor-not-allowed bg-night-100' : 'bg-white',
        props.isUserSource && '!bg-azure/20',
      )}
      onClick={() => {
        if (props.disabled) return
        props.onClick?.()
      }}
    >
      <span className="text-body-2-strong truncate text-night-800">{props.label}</span>
      {props.onDelete && (
        <span
          aria-label={t('action.close')}
          role="img"
          onClick={(e) => {
            e.stopPropagation()
            if (props.disabled) return
            props.onDelete?.()
          }}
          className={clsx(
            'ml-auto rounded-[4px]',
            !!props.onDelete && !props.disabled && 'cursor-pointer',

            !!props.onDelete && (props.onClick ? 'hover:bg-night-100' : 'hover:bg-night-50'),
          )}
        >
          {props.loading ? <Loader iconSize={16} /> : <IconX size={16} />}
        </span>
      )}
    </div>
  )
}
