import type { GridApi } from '@mui/x-data-grid-premium'
import type { DataGridPremiumProps } from '@mui/x-data-grid-premium'
import { type RefObject, createContext, useContext } from 'react'

type DataTableContextValue = {
  tableId: string
  apiRef: RefObject<GridApi>
  rootProps: DataGridPremiumProps
  dataGridRootProps: DataGridPremiumProps
  dataGridProps: DataGridPremiumProps
}

export const DataTableContext = createContext<DataTableContextValue | undefined>(undefined)

export const useDataTableContext = () => {
  const context = useContext(DataTableContext)

  if (!context) {
    throw new Error('useDataTableContext must be used within a DataTableContextProvider')
  }

  return context
}
