import { DataTableBox } from './DataTableBox'
import { DataTableColumnsPanelButton } from './DataTableColumnsPanelButton'
import { DataTableExportButton } from './DataTableExportButton'
import { DataTableFooter } from './DataTableFooter'
import { DataTablePagination } from './DataTablePagination'
import { DataTableRoot } from './DataTableRoot'
import { DataTableTable } from './DataTableTable'
import { DataTableToolbar } from './DataTableToolbar'

export const DataTable = {
  Root: DataTableRoot,
  Box: DataTableBox,
  Footer: DataTableFooter,
  Pagination: DataTablePagination,
  Table: DataTableTable,
  Toolbar: DataTableToolbar,
  ExportButton: DataTableExportButton,
  ColumnsPanelButton: DataTableColumnsPanelButton,
}
