import type { GridActionsColDef } from '@mui/x-data-grid-premium'
import { useCallback, useMemo } from 'react'
import { DataTable } from '~/components/UI/DataTableComposed'
import { ViewDetailsAction } from '~/components/ViewDetailsAction'
import { useCampaignsQuery } from '~/pages/Planning/PromotionalCampaigns/PromotionalCampaignsPage/hooks/useCampaignsQuery'
import type { Campaign } from '~/types/apiContracts'
import { usePaginationModel } from '~/utils/dataGrid'
import { getColumns } from './helpers'
import type { InventoryBalancingTableProps } from './types'

export const InventoryBalancingTable = ({ onView }: InventoryBalancingTableProps) => {
  const { paginationModel, setPaginationModel } = usePaginationModel(25)

  const campaignsQuery = useCampaignsQuery({ paginationModel })

  const total = campaignsQuery.data?.total || 0
  const rows = campaignsQuery.data?.items || []

  const apiColumns = campaignsQuery.data?.columns

  const getActions: GridActionsColDef<Campaign>['getActions'] = useCallback(() => {
    return [<ViewDetailsAction key="viewMoreDetails" onClick={() => onView()} />]
  }, [onView])

  const columns = useMemo(() => getColumns({ getActions, apiColumns }), [getActions, apiColumns])

  return (
    <DataTable.Root
      columns={columns}
      tableId="campaigns"
      rowCount={total}
      rows={rows}
      pagination
      paginationMode="server"
      loading={campaignsQuery.isFetching}
      isLoadingFirstTime={campaignsQuery.isLoading}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      initialState={{
        pinnedColumns: {
          right: ['actions'],
        },
      }}
    >
      <div className="overflow-auto">
        <DataTable.Box>
          <DataTable.Toolbar>
            <DataTable.ExportButton />

            <DataTable.ColumnsPanelButton />
          </DataTable.Toolbar>

          <DataTable.Table />
        </DataTable.Box>

        <DataTable.Footer>
          <DataTable.Pagination />
        </DataTable.Footer>
      </div>
    </DataTable.Root>
  )
}
