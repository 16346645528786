import type { User } from 'firebase/auth'
import ky from 'ky'
import type { Permission } from '~/types/apiContracts'
import type { RoutePath } from '~/types/shared'

export const SIGN_OUT_KEY = 'signOut'

/**
 * Unlike other providers supported by Firebase Auth,
 * Microsoft does not provide a photo URL and instead,
 * the binary data for a profile photo has to be requested via Microsoft Graph API.
 */
export async function fetchImageMicrosoft(token: string) {
  const res = await ky
    .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .blob()

  return URL.createObjectURL(res)
}

function convertRoutePathToModuleCodes(path: RoutePath): string[] {
  return path.split('/').filter(Boolean).slice(0, 3)
}

export function convertModuleCodeToUrl(moduleCode: string) {
  // biome-ignore lint/style/useTemplate: easy to read
  return '/' + moduleCode.replace(/\./g, '/')
}

export function checkModuleAccess(path: RoutePath, permissions: Permission[]) {
  if (!permissions.length) {
    return false
  }

  const convertedPath = convertRoutePathToModuleCodes(path)
  const [moduleCode, submoduleCode, innerSubmoduleCode] = convertedPath

  if (!moduleCode) {
    return false
  }

  if (!submoduleCode) {
    return permissions.some((permission) => {
      const permissionModuleCode = permission.code.split('.')[0]

      return permission.active && permissionModuleCode === moduleCode
    })
  }

  if (moduleCode === 'administration') {
    return permissions.some(
      (permission) =>
        permission.active &&
        permission.code.startsWith(
          [moduleCode, submoduleCode, innerSubmoduleCode].filter(Boolean).join('.'),
        ),
    )
  }

  return permissions.some(
    (permission) => permission.active && permission.code === `${moduleCode}.${submoduleCode}`,
  )
}

export const syncUserDetails = async (user: User) => {
  // user details come from the sign in with google popup,
  // but no on page refresh, so we store them in localStorage
  // to persist them
  const storedDisplayName = localStorage.getItem('user.displayName')
  const storedEmail = localStorage.getItem('user.email')
  const storedPhotoURL = localStorage.getItem('user.photoURL')
  const storedUserToken = localStorage.getItem('user.oauthToken')

  let displayName = user.displayName
  let email = user.email
  let photoURL = user.photoURL

  if (!photoURL && !storedPhotoURL && storedUserToken) {
    try {
      photoURL = await fetchImageMicrosoft(storedUserToken)
    } catch (error) {
      photoURL = null
    }
  }

  if (displayName != null && storedDisplayName !== displayName) {
    localStorage.setItem('user.displayName', displayName)
  }

  if (email != null && storedEmail !== email) {
    localStorage.setItem('user.email', email)
  }

  if (photoURL != null && storedPhotoURL !== photoURL) {
    localStorage.setItem('user.photoURL', photoURL)
  }

  displayName ??= storedDisplayName
  email ??= storedEmail
  photoURL ??= storedPhotoURL

  return { ...user, displayName, email, photoURL }
}
