import { gridColumnDefinitionsSelector } from '@mui/x-data-grid-premium'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../Checkbox'
import { CheckboxControlled } from '../CheckboxControlled'
import { useDataTableContext } from '../DataTableComposed/DataTableContext'
import { DataTableExportDialogSection } from './DataTableExportDialogSection'
import { checkIfColumnIsExportable } from './helpers'
import type { DataTableExportDialogFormValues } from './types'

export const DataTableExportDialogSectionColumns = () => {
  const { apiRef } = useDataTableContext()
  const formik = useFormikContext<DataTableExportDialogFormValues>()
  const { t } = useTranslation()

  const id = 'dataTableExportDialogColumns'

  const columns = gridColumnDefinitionsSelector(apiRef)

  const exportableColumns = columns.filter(checkIfColumnIsExportable)

  const allColumnsSelected = formik.values.columns.length === exportableColumns.length

  const toogleAllColumns = () => {
    if (allColumnsSelected) {
      formik.setFieldValue('columns', [])
      return
    }

    formik.setFieldValue(
      'columns',
      exportableColumns.map((col) => col.field),
    )
  }

  return (
    <DataTableExportDialogSection title={t('columns')} titleId={id}>
      <Checkbox
        label={t('selectAll')}
        checked={allColumnsSelected}
        onChange={() => toogleAllColumns()}
        className="mb-6"
      />
      <div role="group" aria-labelledby={id} className="grid gap-2 @sm:grid-cols-2 @xl:grid-cols-4">
        {exportableColumns.map((col) => {
          return (
            <CheckboxControlled
              key={col.field}
              name="columns"
              value={col.field}
              label={col.headerName}
            />
          )
        })}
      </div>
    </DataTableExportDialogSection>
  )
}
